













































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SortParameter, toSortingParameters } from '@model/Table';
import { PaginationParametersDto, SortingParametersDto } from '@services/dtos/common';
import TablePagination from '@components/TablePagination.vue';
import { Envelope, getAreaName, getBusinessName, getPeriodName } from '@model/Common';
import { formatDate } from '@helpers';
import { getAllAndAssignedUsers } from '@/api';
import { debounce, isEmpty } from 'lodash-es';
import { deleteAllDataProvider, deleteDataProvider, substituteDataOwner, substituteDataProvider } from '@/api/users';
import { ApiError, toApiError } from '@model/ApiError';
import { AxiosError } from 'axios';

@Component({
  components: {
    'table-pagination': TablePagination
  }
})
export default class UserRightsTable extends Vue {
  @Prop({ default: () => [], required: true, type: Array }) users!: User[]; 
    
  allAndAssignedUsers: User[] = [];

  fetchingData = false;
  savingChanges = false;
  segmentNameFilterModel = null;

  filterWatchers: (() => void)[] = [];

  onlyNewFilter: true | null = null;

  onSegmentNameFilterChangedLazy = debounce(this.onSegmentNameFilterChanged, 1000);

  isNewOptions = [
    { text: 'All', value: null },
    { text: 'New', value: true }
  ];

  defaultSortOrder: SortParameter[] = [
    {
      direction: 'asc',
      field: 'segmentName',
      sortField: 'segmentName'
    }
  ];

  totalCount = 0;

  userRoles: { id: string; name: string }[] = [
    {
      id: '-1',
      name: 'All'
    },
    {
      id: 'data_owner',
      name: 'Data owner'
    },
    {
      id: 'data_provider',
      name: 'Data provider'
    }
  ];

  brokerFilters: User[] = [];

  userRoleFilter: { id: string; name: string }[] = [];

  periodsFilters: {
    areaIds: number[];
    businessIds: number[];
    frequencyIds: number[];
  } = {
    areaIds: [],
    businessIds: [],
    frequencyIds: []
  };

  segmentNameFilter: string | null = null;

  pagination: PaginationParametersDto = {
    perPage: 10,
    page: 1
  };

  get tableRef(): any {
    return this.$refs.vuetable;
  }

  fields = [
    {
      name: 'broker-slot',
      title: 'Broker',
      sortField: 'brokerName',
      titleClass: 'sorting'
    },
    {
      name: 'userRole',
      title: 'User Role',
      formatter: (userRole: UserRole) => (userRole === 'data_owner' ? 'Data owner' : 'Data provider')
    },
    {
      name: 'area-slot',
      title: 'Area',
      sortField: 'areaName',
      titleClass: 'sorting'
    },
    {
      name: 'business-slot',
      title: 'Business',
      sortField: 'businessName',
      titleClass: 'sorting'
    },
    {
      name: 'frequency-slot',
      title: 'Periods',
      sortField: 'frequencyName',
      titleClass: 'sorting'
    },
    {
      name: 'segment-name-slot',
      title: 'Segments',
      sortField: 'segmentName',
      titleClass: 'sorting'
    },
    {
      name: 'createdDate',
      title: 'Created Date',
      sortField: 'createdDate',
      titleClass: 'sorting',
      formatter: (createdDate: number | null) => (createdDate !== null ? formatDate(createdDate) : null)
    },
    {
      name: 'startDate',
      title: 'Start Date',
      sortField: 'startDate',
      titleClass: 'sorting',
      formatter: (startDate: number | null) => (startDate !== null ? formatDate(startDate) : null)
    },
    {
      name: 'remove-data-provider-slot',
      title: ''
    }
  ];

  async created(): Promise<void> {
    this.watchFilters();
    this.allAndAssignedUsers = await getAllAndAssignedUsers();
  }

  watchFilters() {
    const setFirstPageAndRefreshTable = () => {
      this.pagination.page = 1;
      this.refreshTable();
    };

    const setFirstPageAndRefreshTableLazy = debounce(setFirstPageAndRefreshTable, 1000);

    this.filterWatchers.push(this.$watch(() => this.onlyNewFilter, setFirstPageAndRefreshTable));
    this.filterWatchers.push(this.$watch(() => this.segmentNameFilter, setFirstPageAndRefreshTable));

    this.filterWatchers.push(
      this.$watch(() => this.brokerFilters, setFirstPageAndRefreshTableLazy, { deep: true })
    );

    this.filterWatchers.push(
      this.$watch(() => this.userRoleFilter, setFirstPageAndRefreshTableLazy, { deep: true })
    );

    this.filterWatchers.push(
      this.$watch(() => this.periodsFilters, setFirstPageAndRefreshTableLazy, { deep: true })
    );
  }

  onSegmentNameFilterChanged(segmentName: string | null) {
    this.segmentNameFilter = segmentName;
  }

  paginationChanged() {
    this.refreshTable();
  }
  async removeAllDataProvider(): Promise<void> {
      let userId = this.brokerFilters.map(x => x.userId)[0];

      const isUserSure = await this.showPrompt(
          'Are you sure to remove the user as data provider for ALL time series?'
      );

      if (isUserSure) {
          this.savingChanges = true;
          try {
              await deleteAllDataProvider(userId);
              this.refreshTable();
          } catch (error) {
              const apiError = error as ApiError;

              switch (apiError.type) {
                  case 'not_found':
                      this.showError(apiError.message ?? 'Not Found');
                      break;
                  case 'bad_request':
                      this.showError(apiError.message ?? 'Bad Request');
                      break;
                  case 'internal_server_error':
                  case 'action_not_found':
                  case 'incorrect_payload':
                  default:
                      this.showError('Internal Server Error');
                      break;
              }
          } finally {
              this.savingChanges = false;
          }
      }
  }
  
  async removeDataProvider(segmentId: number, userId: string): Promise<void> {
    if (this.savingChanges) {
      return;
    }

    const isUserSure = await this.showPrompt(
      'Are you sure to remove the user as data provider to the time series?'
    );

    if (isUserSure) {
      this.savingChanges = true;
      try {
        await deleteDataProvider(segmentId, userId);
        this.refreshTable();
      } catch (error) {
        const apiError = error as ApiError;

        switch (apiError.type) {
          case 'not_found':
            this.showError(apiError.message ?? 'Not Found');
            break;
          case 'bad_request':
            this.showError(apiError.message ?? 'Bad Request');
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      } finally {
        this.savingChanges = false;
      }
    }
  }

  async onBrokerChanged(
    user: User,
    { segmentId, userRole, userId }: { segmentId: number; userRole: UserRole; userId: string }
  ): Promise<void> {
    if (user.userId === userId) {
      return;
    }

    this.savingChanges = true;

    try {
      switch (userRole) {
        case 'data_owner':
          await substituteDataOwner(segmentId, user.userId);
          break;
        case 'data_provider':
          await substituteDataProvider(segmentId, user.userId, userId);
          break;
      }
      this.refreshTable();
    } catch (error) {
      const apiError = error as ApiError;

      switch (apiError.type) {
        case 'not_found':
          this.showError(apiError.message ?? 'Not Found');
          break;
        case 'bad_request':
          this.showError(apiError.message ?? 'Bad Request');
          break;
        case 'internal_server_error':
        case 'action_not_found':
        case 'incorrect_payload':
        default:
          this.showError('Internal Server Error');
          break;
      }
    } finally {
      this.savingChanges = false;
    }
  }

  resetFilterValues() {
    this.unWatchFilters();
    this.pagination.page = 1;
    this.onlyNewFilter = null;
    this.brokerFilters = [];
    this.periodsFilters = {
      areaIds: [],
      businessIds: [],
      frequencyIds: []
    };
    this.userRoleFilter = [];
    this.segmentNameFilterModel = null;
    this.segmentNameFilter = null;
    this.watchFilters();
    this.refreshTable();
  }

  unWatchFilters() {
    for (let index = 0; index < this.filterWatchers.length; index++) {
      const watcher = this.filterWatchers[index];
      watcher();
    }
    this.filterWatchers = [];
  }

  toAreaName(id: number) {
    return getAreaName(this.$store.state.areas, id);
  }

  toBusinessName(id: number) {
    return getBusinessName(this.$store.state.businesses, id);
  }

  toPeriodName(id: number) {
    return getPeriodName(this.$store.state.periods, id);
  }

  transformResponse(r: UserTimeSeriesQueryResultDto) {
    this.totalCount = r.totalCount;

    return {
      data: r.entries,
      links: {
        pagination: {
          pagination: {
            total: r.totalCount,
            per_page: r.perPage,
            current_page: r.page,
            last_page: Math.ceil(r.totalCount / r.perPage),
            from: (r.page - 1) * r.perPage + 1,
            to: r.page * r.perPage
          }
        }
      }
    };
  }

  makeQueryParams(sortOrder: SortParameter[]): UserTimeSeriesQueryDto {
    const userIds = this.brokerFilters.map(x => x.userId);
    const userRole =
      this.userRoleFilter.length == 1 && this.userRoleFilter[0].id !== '-1'
        ? this.userRoleFilter[0].id
        : null;

    const segmentName = !isEmpty(this.segmentNameFilter) ? this.segmentNameFilter : null;
    const onlyNew = this.onlyNewFilter;

    const filters = {
      userIds: isEmpty(userIds) ? undefined : userIds,
      userRole: isEmpty(userRole) ? undefined : userRole!,
      areaIds: isEmpty(this.periodsFilters.areaIds) ? undefined : this.periodsFilters.areaIds,
      businessIds: isEmpty(this.periodsFilters.businessIds) ? undefined : this.periodsFilters.businessIds,
      frequencyIds: isEmpty(this.periodsFilters.frequencyIds) ? undefined : this.periodsFilters.frequencyIds,
      segmentName: isEmpty(segmentName) ? undefined : segmentName!,
      onlyNew: onlyNew ?? undefined
    };

    const query: UserTimeSeriesQueryDto = {
      pagination: {
        page: this.pagination.page,
        perPage: this.pagination.perPage
      },
      filters: Object.values(filters).every(el => el === undefined) ? null : filters,
      sorting: sortOrder.length ? toSortingParameters(sortOrder[0]) : null
    };

    return query;
  }

  errorOccured(error: AxiosError) {
    if (process.env.NODE_ENV !== 'production') {
      console.dir(error);
    }
    const apiError = toApiError(error);
    switch (apiError.type) {
      case 'internal_server_error':
      case 'action_not_found':
      case 'incorrect_payload':
      default:
        this.showError('Internal Server Error');
        break;
    }
  }

  onRowClass(dataItem: UserTimeSeriesEntryDto, _) {
    return dataItem.isNew ? 'no-providers' : '';
  }

  private async showPrompt(message: string, cancelTitle?: string): Promise<boolean> {
    return await this.$bvModal.msgBoxConfirm(message, {
      title: 'Warning',
      headerBgVariant: 'card-header-label',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Yes',
      cancelTitle: cancelTitle ? cancelTitle : 'Cancel',
      hideHeaderClose: true,
      centered: true
    });
  }

  private showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true
    });
  }

  private refreshTable() {
    this.tableRef?.refresh();
  }
}

interface User {
  userId: string;
  name: string;
  isAdmin: boolean;
}

interface UserTimeSeriesQueryFiltersDto {
  userIds?: string[];
  userRole?: string;
  areaIds?: number[];
  businessIds?: number[];
  frequencyIds?: number[];
  segmentName?: string;
  onlyNew?: true;
}

interface UserTimeSeriesEntryDto {
  segmentId: number;
  areaId: number;
  areaName: string;
  businessId: number;
  businessName: string;
  frequencyId: number;
  frequencyName: string;
  segmentName: string;
  createdDate: number | null;
  startDate: number | null;
  userId: string;
  userName: string;
  isNew: number;
  userRole: string;
}

interface UserTimeSeriesQueryResultDto {
  page: number;
  perPage: number;
  totalCount: number;
  entries: UserTimeSeriesEntryDto[];
}

interface UserTimeSeriesQueryDto {
  filters: UserTimeSeriesQueryFiltersDto | null;
  sorting: SortingParametersDto | null;
  pagination: PaginationParametersDto;
}

type UserRole = 'data_owner' | 'data_provider';
