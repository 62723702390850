
























































































































import { Component, Vue } from 'vue-property-decorator';
import { debounce, isEmpty } from 'lodash-es';
import { eventBus } from '../../main';

import { SortParameter, toSortingParameters } from '@model/Table';
import {
  getAreaName,
  getBusinessName,
  getDataOwnerName,
  getSizeName,
  getPeriodName,
  PaginationParameters
} from '@model/Common';
import TablePagination from '@components/TablePagination.vue';
import {
  ManualTimeSeriesEntryDto,
  ManualTimeSeriesQueryDto,
  ManualTimeSeriesQueryResultDto
} from '@services/dtos/time-series';
import {
  SegmentChangeDto,
  SegmentNameChangeDto,
  SegmentSortOrderChangeDto,
  SegmentStatusChangeDto
} from '@services/dtos/segment-updates';
import { exhaustiveCheck, formatDate } from '@helpers';
import { getUnitName } from '@services/time-series';
import { ApiError, toApiError } from '@model/ApiError';
import { AxiosError } from 'axios';
import { updateManualTimeSeries } from '@/api';

interface SegmentSortOrderChange {
  type: 'sort_order_change';
  sortOrder: number;
}

interface SegmentStatusChange {
  type: 'status_change';
  status: 'active' | 'inactive';
}

interface SegmentNameChange {
  type: 'name_change';
  name: string;
}

type SegmentDataChange = SegmentSortOrderChange | SegmentStatusChange | SegmentNameChange;

interface SegmentChanges {
  id: number;
  changes: SegmentDataChange[];
}

interface TimeSeriesTableEntry {
  id: number;
  areaId: number;
  businessId: number;
  name: string;
  unit: number;
  frequencyId: number;
  dataOwnerId: string;
  active: boolean;
  sortOrder: number | null;
  sizeId: number | null;

  createdAt: string;
  startedAt: string;
}

@Component({
  components: {
    'table-pagination': TablePagination
  }
})
export default class ExistingManualTimeSeries extends Vue {
  fetchingData = false;
  savingChanges = false;

  optionsTimeSeriesFilter = [
    { text: 'All', value: null },
    { text: 'Inactive', value: false },
    { text: 'Active', value: true }
  ];

  totalCount = 0;
  pagination: PaginationParameters = {
    perPage: 10,
    page: 1
  };

  onSegmentNameChangedLazy = debounce(this.onSegmentNameChanged, 500);

  segmentName: string | null = null;
  segmentId: number | null = null;

  filters: ExistingManualTimeSeriesFilter = {
    area: null,
    business: null,
    frequency: null,
    segmentId: null,
    dataOwnerIds: [],
    segmentName: null,
    isActive: null
  };

  fields = [
    {
      name: 'area-slot',
      title: 'Area',
      sortField: 'area',
      titleClass: 'sorting'
    },
    {
      name: 'business-slot',
      title: 'Business',
      sortField: 'business',
      titleClass: 'sorting'
    },
    {
      name: 'frequency-slot',
      title: 'Periods',
      sortField: 'frequency',
      titleClass: 'sorting'
    },
    {
      name: 'segment-name-slot',
      title: 'Segments',
      sortField: 'segment',
      titleClass: 'sorting'
    },
    {
      name: 'id',
      title: 'Segment Id',
      sortField: 'segmentId',
      titleClass: 'sorting',
      dataClass: 'text-center'
    },
    {
      name: 'sort-order-slot',
      title: 'Sort order',
      sortField: 'sortOrder',
      titleClass: 'sorting'
    },
    {
      name: 'data-owner-slot',
      title: 'Data Owner'
    },
      {
          name: 'size-slot',
          title: 'Vessel segment',
          dataClass: 'text-center'
      },
      {
      name: 'createdAt',
      title: 'Created Date',
      sortField: 'createdAt',
      titleClass: 'sorting'
    },
    {
      name: 'startedAt',
      title: 'Start Date'
    },
    {
      name: 'unit-slot',
      title: 'Unit'
    },
    {
      name: 'status-slot',
      title: 'Status'
    }
  ];

  get tableRef(): any {
    return this.$refs.vuetable;
  }

  get entries(): ManualTimeSeriesEntryDto[] {
    return this.tableRef.tableData;
  }

  get hasUnsavedChanges() {
    return this.dataChanges.length > 0;
  }

  isProduction = process.env.NODE_ENV === 'production';

  private dataChanges: SegmentChanges[] = [];

  toUnitName(unit: any) {
    return getUnitName(unit);
  }

  toAreaName(id: number) {
    return getAreaName(this.$store.state.areas, id);
  }

  toBusinessName(id: number) {
    return getBusinessName(this.$store.state.businesses, id);
  }

  toPeriodName(id: number) {
    return getPeriodName(this.$store.state.periods, id);
  }

  toDataOwnerName(id: string) {
    var name = getDataOwnerName(this.$store.state.dataOwners, id);
      if (name === 'Unknown')
          name = getDataOwnerName(this.$store.state.users, id);
    return name;
  }

toSizeName(id: number) {
    return getSizeName(this.$store.state.sizes, id);
}

  created() {
    this.setupWatchers();

    eventBus.$on('onSaveClick', this.onSaveClicked);
  }

  beforeDestroy() {
    eventBus.$off('onSaveClick', this.onSaveClicked);
  }

  private setupWatchers() {
    this.$watch(
      () => this.filters,
      debounce(() => {
        this.pagination = {
          page: 1,
          perPage: this.pagination.perPage
        };
      }, 1000),
      { deep: true }
    );

    this.$watch(() => this.pagination, this.debouncedTableRefresh, { deep: true });

    this.$watch(
      () => this.segmentName,
      debounce(v => {
        if (this.filters.segmentName !== v) {
          this.filters.segmentName = v;
        }
      }, 800)
    );

    this.$watch(
      () => this.segmentId,
      debounce(v => {
        if (this.filters.segmentId !== v) {
          this.filters.segmentId = v;
        }
      }, 800)
    );
  }

  resetFilterValues() {
    this.segmentName = null;
    this.segmentId = null;

    this.filters = {
      area: null,
      business: null,
      frequency: null,
      segmentId: null,
      isActive: null,
      segmentName: null,
      dataOwnerIds: []
    };

    this.pagination.page = 1;
  }

  makeQueryParams(sortOrder: SortParameter[]) {
    const query: ManualTimeSeriesQueryDto = {
      pagination: {
        page: this.pagination.page,
        perPage: this.pagination.perPage
      },
      filters: {
        areaId: this.filters.area ?? undefined,
        businessId: this.filters.business ?? undefined,
        frequencyId: this.filters.frequency ?? undefined,
        isActive: this.filters.isActive ?? undefined,
        segmentId: this.filters.segmentId === null ? undefined : this.filters.segmentId.toString(),
        dataOwnerIds: this.filters.dataOwnerIds.length ? this.filters.dataOwnerIds : undefined,
        name: isEmpty(this.filters.segmentName?.trim()) ? undefined : this.filters.segmentName!.trim()
      }
    };

    query.sorting = sortOrder.length ? toSortingParameters(sortOrder[0]) : null;

    return query;
  }

  transformResponse(r: ManualTimeSeriesQueryResultDto) {
    this.totalCount = r.totalCount;

    return {
      data: this.toTimeSeriesTableEntry(r.entries),
      links: {
        pagination: {
          pagination: {
            total: r.totalCount,
            per_page: r.perPage,
            current_page: r.page,
            last_page: Math.ceil(r.totalCount / r.perPage),
            from: (r.page - 1) * r.perPage + 1,
            to: r.page * r.perPage
          }
        }
      }
    };
  }

  private toTimeSeriesTableEntry(entries: ManualTimeSeriesEntryDto[]): TimeSeriesTableEntry[] {
    return entries.map(e => {
      const updates = this.dataChanges.find(x => x.id === e.id)?.changes ?? [];

      updates.forEach(u => {
        switch (u.type) {
          case 'status_change': {
            e.active = u.status === 'active';
            break;
          }
          case 'sort_order_change': {
            e.sortOrder = u.sortOrder;
            break;
          }
          case 'name_change': {
            e.name = u.name;
            break;
          }
          default: {
            exhaustiveCheck(u);
            break;
          }
        }
      });

      return {
        id: e.id,
        areaId: e.areaId,
        businessId: e.businessId,
        name: e.name,
        frequencyId: e.frequencyId,
        active: e.active,
        sortOrder: e.sortOrder,
        dataOwnerId: e.dataOwnerId,
        unit: e.unit,
        sizeId: e.sizeId,

        createdAt: e.createdAt ? formatDate(e.createdAt) : '',
        startedAt: e.startedAt ? formatDate(e.startedAt) : ''
      };
    });
  }

  onSortOrderChanged(entry: ManualTimeSeriesEntryDto) {
    this.addSegmentChange(entry.id, {
      type: 'sort_order_change',
      sortOrder: entry.sortOrder!
    });
  }

  onStatusChanged(entry: ManualTimeSeriesEntryDto) {
    this.addSegmentChange(entry.id, {
      type: 'status_change',
      status: entry.active ? 'active' : 'inactive'
    });
  }

  onSegmentNameChanged(segmentName: string, segmentId: number) {
    this.addSegmentChange(segmentId, {
      type: 'name_change',
      name: segmentName
    });
  }

  onSaveClicked() {
    if (this.savingChanges || !this.hasUnsavedChanges) {
      return;
    }

    this.savingChanges = true;

    this.$store.commit('startGlobalSaveLoading');
    this.saveSegmentChanges()
      .then(() => {
        this.dataChanges = [];
        this.$toast.success('The segment changes were successfully saved!');
        this.tableRef.refresh();
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'bad_request':
            this.showError(error.message ?? 'Bad request');
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => {
        this.savingChanges = false;
        this.$store.commit('endGlobalSaveLoading');
      });
  }

  private addSegmentChange(id: number, change: SegmentDataChange) {
    let segmentChanges = this.dataChanges.find(x => x.id === id);
    if (!segmentChanges) {
      segmentChanges = { id: id, changes: [] };
      this.dataChanges.push(segmentChanges);
    }

    segmentChanges.changes = [...segmentChanges.changes.filter(x => x.type !== change.type), change];
  }

  private saveSegmentChanges() {
    const payload: SegmentChangeDto[] = [];

    this.dataChanges.forEach(segmentChanges => {
      segmentChanges.changes.forEach(change => {
        let changeData: SegmentSortOrderChangeDto | SegmentStatusChangeDto | SegmentNameChangeDto | null =
          null;
        switch (change.type) {
          case 'sort_order_change':
            changeData = { sortOrder: change.sortOrder };
            break;
          case 'status_change':
            changeData = { status: change.status === 'active' };
            break;
          case 'name_change':
            changeData = { name: change.name };
            break;
        }

        payload.push({
          id: segmentChanges.id,
          changeType: change.type,
          change: { ...changeData }
        });
      });
    });

    return updateManualTimeSeries(payload);
  }

  errorOccured(error: AxiosError) {
    if (process.env.NODE_ENV !== 'production') {
      console.dir(error);
    }
    const apiError = toApiError(error);
    switch (apiError.type) {
      case 'internal_server_error':
      case 'action_not_found':
      case 'incorrect_payload':
      default:
        this.showError('Internal Server Error');
        break;
    }
  }

  private showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true
    });
  }

  private refreshTable() {
    this.tableRef.refresh();
  }

  private debouncedTableRefresh = debounce(() => this.refreshTable(), 200);
}
export interface ExistingManualTimeSeriesFilter {
  area: number | null;
  business: number | null;
  frequency: number | null;
  segmentId: number | null;
  dataOwnerIds: string[];
  segmentName: string | null;
  isActive: boolean | null;
}
