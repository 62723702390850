import Vue from 'vue';

import configureExternalLibraries from './externals.config';
configureExternalLibraries();

import App from './App.vue';
import router from './router';
import store from './store';

import configureAxios from './axios.config';
configureAxios();

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'flatpickr/dist/flatpickr.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '@/css/main.scss';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import './extensions/StringExtensions';

export const eventBus = new Vue();

Vue.config.productionTip = false;

Vue.use(VueToast);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
