












import { Component, Vue, Model } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MBSegmentIdInput extends Vue {
  @Model("change", { default: null }) value!: number | null;

  updateSelected(value: number) {
    this.$emit("change", value);
  }
}
