import { render, staticRenderFns } from "./add-new-data-provider.vue?vue&type=template&id=31700bc9&scoped=true&"
import script from "./add-new-data-provider.vue?vue&type=script&lang=ts&"
export * from "./add-new-data-provider.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31700bc9",
  null
  
)

export default component.exports