



















import { Size } from '@model/Common';
import { Component, Vue, Prop, Model } from 'vue-property-decorator';

interface SelectOption {
    id: number;
    name: string;
}

@Component({
    components: {}
})
export default class MBSizeSelect extends Vue {
    @Model('change', { default: null }) value!: SelectOption | SelectOption[] | number | number[] | null;
    @Prop({ default: false, type: Boolean }) allowAny!: boolean;

    get options(): SelectOption[] {
        const result: SelectOption[] = [];

        this.$store.state.sizes.forEach((a: Size) => result.push(a));

        return result;
    }

    isSelectOption(object: unknown): object is SelectOption {
        return (
            'name' in (object as any) &&
            'id' in (object as any) &&
            typeof (object as any).name === 'string' &&
            typeof (object as any).id === 'number'
        );
    }

    get controlValue(): SelectOption | null {
        if (!this.value) {
            return null;
        }

        if (typeof this.value === 'number') {
            return this.options.find(x => x.id === this.value) ?? null;
        }

        if (this.isSelectOption(this.value)) {
            return this.value;
        }

        return null;
    }

    updateSelected(option: SelectOption) {
        this.$emit('change', option?.id);
    }

}
