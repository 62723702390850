























import { Component, Vue } from 'vue-property-decorator';

import TimeSeriesAreaSelect from './controls/time-series-area-select.vue';
import TimeSeriesBusinessSelect from './controls/time-series-business-select.vue';
import TimeSeriesPeriodsSelect from './controls/time-series-periods-select.vue';
import TimeSeriesSegmentInput from './controls/time-series-segment-input.vue';
import TimeSeriesImportSourceSelect from './controls/time-series-import-source-select.vue';
import TimeSeriesImportCodeInput from './controls/time-series-import-code-input.vue';
import NewImportedTimeSeries from './new-imported-time-series.vue';
import ExistingImportedTimeSeries from './existing-imported-time-series.vue';
import {
  getAllAreas,
  getAllBusinesses,
  getAllDataOwners,
  getAllImportSources,
  getAllPeriods,
  getAllSizes,
  getCurrentUser
} from '@/api';
import {User} from "@model/User";
import AccessDenied from "@views/access-denied.vue";
import ContentLoading from "@views/content-loading.vue";

Component.registerHooks(['beforeRouteLeave']);
@Component({
  components: {
    ContentLoading,
    'access-denied': AccessDenied,
    'time-series-area-select': TimeSeriesAreaSelect,
    'time-series-business-select': TimeSeriesBusinessSelect,
    'time-series-periods-select': TimeSeriesPeriodsSelect,
    'time-series-segment-input': TimeSeriesSegmentInput,
    'time-series-import-source-select': TimeSeriesImportSourceSelect,
    'time-series-import-code-input': TimeSeriesImportCodeInput,
    'new-time-series': NewImportedTimeSeries,
    'existing-time-series': ExistingImportedTimeSeries
  }
})
export default class TimeSeriesImport extends Vue {
  pageLoading = false;
  isProduction = process.env.NODE_ENV === 'production';

  public currentUser: User = { isAdmin: false, name: '', isActive: false, userId: '', email: '' };
  get existingTimeSeriesTable(): ExistingImportedTimeSeries {
    return this.$refs.existingTimeSeriesTable as ExistingImportedTimeSeries;
  }

  get newTimeSeriesTable(): NewImportedTimeSeries {
    return this.$refs.newTimeSeriesTable as NewImportedTimeSeries;
  }

  get hasUnsavedChanges(): boolean {
    return this.existingTimeSeriesTable.hasUnsavedChanges || this.newTimeSeriesTable.hasUnsavedChanges;
  }

  // COMMON HANDLERS

  onBeforeUnload(event: any) {
    // User is closing tab/browser. If there are unsaved changes, have the browser prompt for confirmation
    if (!this.hasUnsavedChanges) {
      return;
    }

    event.preventDefault();
    event.returnValue = '';
  }

  beforeMount() {
    this.pageLoading = true;
  }
  async created() {
    window.addEventListener('beforeunload', this.onBeforeUnload);
    try {
      this.currentUser = await getCurrentUser();
      await this.restoreCommonData();
    }
    catch (e) {
      console.error('TimeSeriesImport', e);
    }
    finally {
      this.pageLoading = false;
    }
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    // User is moving to another page within the app. If there are unsaved changes, prompt for confirmation

    if (!this.hasUnsavedChanges) {
      next();
      return;
    }

    const leavePage = window.confirm('You have unsaved changes. Do you wish to leave this page?');

    next(leavePage);
  }

  private restoreCommonData() {
    const restoreAreas = getAllAreas().then(data => this.$store.commit('areasReceived', data));

    const restoreBusinesses = getAllBusinesses().then(data => this.$store.commit('businessesReceived', data));

    const restorePeriods = getAllPeriods().then(data => this.$store.commit('periodsReceived', data));

    const restoreDataOwners = getAllDataOwners().then(data => this.$store.commit('dataOwnersReceived', data));

    const restoreSizes = getAllSizes().then(data => this.$store.commit('sizesReceived', data));

    const restoreImportSources = getAllImportSources().then(data =>
      this.$store.commit('importSourcesReceived', data)
    );

    return Promise.all([
      restoreAreas,
      restoreBusinesses,
      restorePeriods,
      restoreDataOwners,
      restoreSizes,
      restoreImportSources
    ]);
  }
}
