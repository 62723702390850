


















import { Component, Vue, Prop, ModelSync } from "vue-property-decorator";
@Component({
  components: {},
  inheritAttrs: false,
})
export default class MTextField extends Vue {
  @ModelSync("value", "input") modelText!: string | null;
  @Prop({ default: "text", type: String }) type!: string;
  @Prop({ default: 0, type: Number }) precision!: number;
  @Prop({ default: (value: string) => value, type: Function })
  formatter!: (value: string | null) => string;
  @Prop({ default: false, type: Boolean })
  showErrorMessage!: boolean;
  @Prop({ default: [], type: Array })
  errorMessages!: string[];

  keyDownPressed(event: KeyboardEvent) {
    if (this.type === "number") {
      if (event.key === "+" || event.key === "e") {
        event.preventDefault();
      }
      if (this.precision === 0 && event.key === ".") {
        event.preventDefault();
      }
      if (event.key === "-") {
        if (this.modelText !== null && this.modelText !== "") {
          event.preventDefault();
          if (this.modelText.includes("-")) {
            this.modelText = this.modelText.replace("-", "");
          } else {
            this.modelText = `-${this.modelText}`;
          }
        }
      }
    }
    this.$emit("keydown", event);
  }

  toFixedPrecision(value: string): string {
    let slittedValue = value.split(".");

    if (slittedValue.length > 1 && slittedValue[1].length > this.precision) {
      slittedValue[1] = slittedValue[1].substr(0, this.precision);
    }

    return slittedValue.join(".");
  }

  toInteger(value: string): string {
    return value.replaceAll(".", "");
  }

  formatText(value: string | null) {
    if (this.type === "number") {
      if (this.precision === 0) {
        value = this.toInteger(value!);
      } else if (this.precision > 0) {
        value = this.toFixedPrecision(value!);
      }

      value = value === "" ? null : value;
    }

    return this.formatter(value);
  }
}
