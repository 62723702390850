



















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { debounce, isEmpty, isNil } from 'lodash-es';
import { eventBus } from '../../main';
import { SortParameter, toSortingParameters } from '@model/Table';
import {
  getAreaName,
  getBusinessName,
  getImportSourceName,
  getPeriodName,
  getSizeName,
  PaginationParameters
} from '@model/Common';
import TablePagination from '@components/TablePagination.vue';
import {
  ImportedTimeSeriesEntryDto,
  ImportedTimeSeriesQueryDto,
  ImportedTimeSeriesQueryResultDto, ImportResultDto
} from '@services/dtos/time-series';
import { formatDate } from '@helpers';
import { getUnitName } from '@services/time-series';
import { AxiosError } from 'axios';
import { ApiError, toApiError } from '@model/ApiError';
import { saveImportedTimeSeriesUpdates, testImport } from '@/api';

interface TimeSeriesTableEntry {
  id: number;
  areaId: number;
  businessId: number;
  name: string;
  frequencyId: number;
  sizeId: number | null;
  active: boolean;
  importSourceKey: string;
  importCode: string;
  sortOrder: number | null;

  createdAt: string;
  startedAt: string;

  initialSegment: string;

  initialImportSourceKey: string;
  initialImportCode: string;

  waitingForServer: boolean;
  testStatus: 'success' | 'failed' | 'not_executed';
}

@Component({
  components: {
    'table-pagination': TablePagination
  }
})
export default class ExistingImportedTimeSeries extends Vue {
  fetchingData = false;
  savingChanges = false;

  optionsTimeSeriesFilter = [
    { text: 'All', value: null },
    { text: 'Inactive', value: false },
    { text: 'Active', value: true }
  ];

  totalCount = 0;
  pagination: PaginationParameters = {
    perPage: 10,
    page: 1
  };

  segmentId: number | null = null;
  segmentName: string | null = null;
  importCode: string | null = null;

  filters: ExistingImportedTimeSeriesFilter = {
    area: null,
    business: null,
    frequency: null,
    segmentId: null,
    importSource: null,
    segmentName: null,
    importCode: null,
    isActive: null
  };

  fields = [
    {
      name: 'area-slot',
      title: 'Area',
      sortField: 'area',
      titleClass: 'sorting'
    },
    {
      name: 'business-slot',
      title: 'Business',
      sortField: 'business',
      titleClass: 'sorting'
    },
    {
      name: 'frequency-slot',
      title: 'Periods',
      sortField: 'frequency',
      titleClass: 'sorting'
    },
    {
      name: 'segment-name-slot',
      title: 'Segments',
      sortField: 'segment',
      titleClass: 'sorting'
    },
    {
      name: 'id',
      title: 'Segment ID',
      sortField: 'segmentId',
      titleClass: 'sorting',
      dataClass: 'text-center'
    },
    {
      name: 'size-slot',
      title: 'Vessel segment'
    },
    {
      name: 'createdAt',
      title: 'Created Date',
      sortField: 'createdAt',
      titleClass: 'sorting'
    },
    {
      name: 'startedAt',
      title: 'Start Date'
    },
    {
      name: 'import-source-slot',
      sortField: 'importSource',
      title: 'Import Source'
    },
    {
      name: 'import-code-slot',
      sortField: 'importCode',
      title: 'ImportCode'
    },
    {
      name: 'test-button-slot',
      title: ''
    },
    {
      name: 'test-status-slot',
      title: ''
    }
  ];

  get tableRef(): any {
    return this.$refs.vuetable;
  }

  get entries(): TimeSeriesTableEntry[] {
    return this.tableRef.tableData;
  }

  get hasUnsavedChanges() {
    return this.entries.some(
      e =>
        e.initialImportSourceKey !== e.importSourceKey ||
        e.initialImportCode !== e.importCode ||
        e.initialSegment !== e.name
    );
  }

  get importTestPassed() {
    return !isNil(this.importTestResult) && this.importTestResult.result === 'Success';
  }

  validationErrors: string[] = [];
  waitingForImportTestResult = false;
  importSourceUnderTest: string | null = null;
  importCodeUnderTest: string | null = null;
  importTestResult: ImportResultDto | null = null;

  toUnitName(unit: any) {
    return getUnitName(unit);
  }

  toAreaName(id: number) {
    return getAreaName(this.$store.state.areas, id);
  }

  toBusinessName(id: number) {
    return getBusinessName(this.$store.state.businesses, id);
  }

  toPeriodName(id: number) {
    return getPeriodName(this.$store.state.periods, id);
  }

  toImportSourceName(key: string) {
    return getImportSourceName(this.$store.state.importSources, key);
  }

  toSizeName(id: number) {
    return getSizeName(this.$store.state.sizes, id);
  }

  created() {
    this.setupWatchers();

    eventBus.$on('onSaveClick', this.onSaveClicked);
  }

  resetFilterValues() {
    this.importCode = null;
    this.segmentName = null;
    this.segmentId = null;

    this.filters = {
      area: null,
      business: null,
      frequency: null,
      segmentId: null,
      importSource: null,
      isActive: null,
      importCode: null,
      segmentName: null
    };

    this.pagination.page = 1;
  }

  makeQueryParams(sortOrder: SortParameter[]) {
    const query: ImportedTimeSeriesQueryDto = {
      pagination: {
        page: this.pagination.page,
        perPage: this.pagination.perPage
      },
      filters: {
        areaId: this.filters.area ?? undefined,
        businessId: this.filters.business ?? undefined,
        frequencyId: this.filters.frequency ?? undefined,
        isActive: this.filters.isActive ?? undefined,
        segmentId: this.filters.segmentId === null ? undefined : this.filters.segmentId.toString(),
        importSource: this.filters.importSource ?? undefined,
        importCode: isEmpty(this.filters.importCode?.trim()) ? undefined : this.filters.importCode!.trim(),
        name: isEmpty(this.filters.segmentName?.trim()) ? undefined : this.filters.segmentName!.trim()
      }
    };

    if (sortOrder.length) {
      query.sorting = toSortingParameters(sortOrder[0]);
    } else {
      query.sorting = {
        sortBy: 'sortOrder',
        sortOrder: 'asc'
      };
    }

    return query;
  }

  transformResponse(r: ImportedTimeSeriesQueryResultDto) {
    this.totalCount = r.totalCount;

    return {
      data: r.entries.map(e => this.toTableEntry(e)),
      links: {
        pagination: {
          pagination: {
            total: r.totalCount,
            per_page: r.perPage,
            current_page: r.page,
            last_page: Math.ceil(r.totalCount / r.perPage),
            from: (r.page - 1) * r.perPage + 1,
            to: r.page * r.perPage
          }
        }
      }
    };
  }

  onTestImportClick(entry: TimeSeriesTableEntry) {
    const canRunTest =
      !this.waitingForImportTestResult && !isNil(entry.importSourceKey) && !isEmpty(entry.importCode);

    if (!canRunTest) {
      return;
    }

    this.waitingForImportTestResult = true;
    this.importSourceUnderTest = this.toImportSourceName(entry.importSourceKey);
    this.importCodeUnderTest = entry.importCode;

    this.$bvModal.show('existing-ts-test-import-modal');

    testImport(entry.importSourceKey, entry.importCode)
      .then(result => {
        this.importTestResult = result;
        entry.testStatus = 'not_executed';
        if(result.result === 'Success') {
          entry.testStatus = 'success';
        }
        if(result.result === 'Failed') {
          entry.testStatus = 'failed';
        }
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => (this.waitingForImportTestResult = false));
  }

  onRowDataChanged(rowData: TimeSeriesTableEntry) {
    this.$nextTick(() => {
      const needTest =
        rowData.initialImportSourceKey !== rowData.importSourceKey ||
        rowData.initialImportCode !== rowData.importCode;

      rowData.testStatus = needTest ? 'not_executed' : 'success';
    });
  }

  errorOccured(error: AxiosError) {
    if (process.env.NODE_ENV !== 'production') {
      console.dir(error);
    }
    const apiError = toApiError(error);
    switch (apiError.type) {
      case 'internal_server_error':
      case 'action_not_found':
      case 'incorrect_payload':
      default:
        this.showError('Internal Server Error');
        break;
    }
  }

  beforeDestroy() {
    eventBus.$off('onSaveClick', this.onSaveClicked);
  }

  private toTableEntry(e: ImportedTimeSeriesEntryDto): TimeSeriesTableEntry {
    return {
      id: e.id,
      areaId: e.areaId,
      businessId: e.businessId,
      frequencyId: e.frequencyId,
      sizeId: e.sizeId,

      createdAt: e.createdAt ? formatDate(e.createdAt) : '',
      startedAt: e.startedAt ? formatDate(e.startedAt) : '',

      name: e.name,
      initialSegment: e.name,

      sortOrder: e.sortOrder,
      active: e.active,

      importSourceKey: e.importSourceKey,
      initialImportSourceKey: e.importSourceKey,

      importCode: e.importCode,
      initialImportCode: e.importCode,

      waitingForServer: false,
      testStatus: 'success'
    };
  }

  private setupWatchers() {
    this.$watch(
      () => this.filters,
      () => {
        this.pagination.page = 1;
        this.debouncedTableRefresh();
      },
      { deep: true }
    );

    this.$watch(() => this.pagination, this.debouncedTableRefresh, { deep: true });

    this.$watch(
      () => this.segmentName,
      debounce(v => {
        if (this.filters.segmentName !== v) {
          this.filters.segmentName = v;
        }
      }, 800)
    );

    this.$watch(
      () => this.segmentId,
      debounce(v => {
        if (this.filters.segmentId !== v) {
          this.filters.segmentId = v;
        }
      }, 800)
    );

    this.$watch(
      () => this.importCode,
      debounce(v => {
        if (this.filters.importCode !== v) {
          this.filters.importCode = v;
        }
      }, 800)
    );
  }

  private onSaveClicked() {
    if (this.savingChanges) {
      return;
    }

    const entriesToSave = this.entries.filter(
      e =>
        e.initialImportSourceKey !== e.importSourceKey ||
        e.initialImportCode !== e.importCode ||
        e.initialSegment !== e.name
    );

    if (!entriesToSave.length) {
      return;
    }

    const validationResult = this.validateEntriesToSave(entriesToSave);
    if (!validationResult.isValid) {
      this.validationErrors = [validationResult.error!];
      this.showValidationFailuresModal();
      return;
    }

    this.savingChanges = true;

    const requestData = entriesToSave.map(e => ({
      segmentId: e.id,
      name: e.name,
      importSource: e.importSourceKey,
      importCode: e.importCode,
      testStatus: e.testStatus
    }));

    this.$store.commit('startGlobalSaveLoading');
    saveImportedTimeSeriesUpdates(requestData)
      .then(() => {
        this.$toast.success('Existing time series changes saved');
        this.refreshTable();
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'validation_error':
            this.validationErrors = error.errors['validationErrors'];
            this.showValidationFailuresModal();
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.$toast.error('Something went wrong! Time series changes may not be saved!');
            break;
        }
      })
      .finally(() => {
        this.savingChanges = false;
        this.$store.commit('endGlobalSaveLoading');
      });
  }

  private showValidationFailuresModal() {
    this.$nextTick(() => this.$bvModal.show('existing-ts-validation-failure-modal'));
  }

  private validateEntriesToSave(entriesToSave: TimeSeriesTableEntry[]): {
    isValid: boolean;
    error?: string;
  } {
    const someEntriesWithoutInput = entriesToSave.some(
      x => isEmpty(x.name) || isEmpty(x.importSourceKey) || isEmpty(x.importCode)
    );

    if (someEntriesWithoutInput) {
      return {
        isValid: false,
        error: 'You need to fill out all the fields and test the time series before saving.'
      };
    }

    const someEntriesWithFailedTest = entriesToSave.some(x => x.testStatus !== 'success');

    if (someEntriesWithFailedTest) {
      return {
        isValid: false,
        error: 'You need to test the time series with successful server connection for saving the changes'
      };
    }

    return { isValid: true };
  }

  private refreshTable() {
    this.tableRef.refresh();
  }

  private debouncedTableRefresh = debounce(() => this.refreshTable(), 200);

  private showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true
    });
  }
}
export interface ExistingImportedTimeSeriesFilter {
  area: number | null;
  business: number | null;
  frequency: number | null;
  segmentId: number | null;
  importSource: string | null;
  segmentName: string | null;
  importCode: string | null;
  isActive: boolean | null;
}
