





































































































import { Component, Vue } from 'vue-property-decorator';
import TimeSeriesFilters from '@components/time-series-filters.vue';
import { User } from '@model/User';
import { eventBus } from '../src/main';
import tabs from '@components/tabs.vue';
import { IRouteLinkParam } from '@model/Common';
import {getCurrentUser} from "@/api";

@Component({
  components: {
    'time-series-filters': TimeSeriesFilters,
    tabs
  }
})
export default class App extends Vue {
  public envLink: string | undefined = '';
  public userInfo: User | null = null;
  public showAccountTooltip = false;
  public showActionsTooltip = false;
  public isManuallyCollapsed: boolean = false;
  private isLoading: boolean = true;
  private isOverdueTimeSeriesOnly: boolean = false;
  sidebarExpanded = false;

  routeLinkParams: IRouteLinkParam[] = [
    { to: '/', text: 'Input' },
    { to: '/time-series-manual', text: 'Time series - Manual' },
    { to: '/time-series-import', text: 'Time Series - Import' },
    { to: '/users', text: 'Users' },
    { to: '/validationRules', text: 'Validation Rules' },
    { to: '/assignRulesTimeSeries', text: 'Assign Rules to Time Series' }
  ];

  routeNamesWithSideBar: string[] = ['/'];

  get showSidebar(): boolean {
    return this.$route.name
      ? this.userIsAdmin && this.routeNamesWithSideBar.includes(this.$route.name)
      : false;
  }

  get globalSaveLoading(): boolean {
    return this.$store.state.globalSaveLoading;
  }

  public get userIsAuthenticated(): boolean {
    return !!this.userInfo?.name;
  }

  async created() {
    console.log('App created, environment: ' + process.env.NODE_ENV);
    this.envLink = process.env.VUE_APP_DIGITAL_PLATFORM_LINK;
    await this.getUserInfo();
  }
  public get userIsAdmin(): boolean {
    return !!this.userInfo?.isAdmin;
  }

  public get userIsActive(): boolean {
    return !!this.userInfo?.isActive;
  }

  private showOverdueTimeSeriesChanged(checked: boolean) {
    this.$store.commit('setOverdueTimeSeries', checked ? true : null);
    eventBus.$emit('appliedFilters');
  }

  public async getUserInfo(): Promise<void> {
    try {
      this.userInfo = await getCurrentUser();
      this.isLoading = false;
    } catch {
      this.logIn();
    }
    finally {
      this.isLoading = false;
    }
  }

  public logIn() {
    location.href = '/Account/SignIn';
  }

  public logOut() {
    location.href = '/Account/LogOut';
  }

  onSaveClick() {
    eventBus.$emit('onSaveClick');
  }
}
