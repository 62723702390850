

























































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IdNamePair, ResponseModalComponent } from '@/models/Common';
import { BModal } from 'bootstrap-vue';
import { associateValidationRules, getValidationRulesBySegmentId } from '@/api/validation-rules';
import { ApiError } from '@model/ApiError';
import { ValidationRuleRequest } from '@model/ValidationRule';
import { getTimeSeriesEmptyExternalSource } from '@/api/time-series';
@Component({
  name: 'create-assign-rules-modal'
})
export default class CreateAssignRulesModal extends Vue {
  $refs!: {
    createAssignRulesModalRef: BModal;
    responseModal: ResponseModalComponent;
    vuetable: any;
  };
  private filtersLoading: boolean = false;

  public filter: {
    areaId: number | null;
    businessId: number | null;
    frequency: number | null;
    segment: {
      value: IdNamePair<number> | null;
      options: IdNamePair<number>[];
    };
    rule: {
      value: ValidationRuleRequest[];
      options: [
        { group: '-- Rules applied to other Time Series in group'; rules: ValidationRuleRequest[] },
        { group: '-- All other Time Series'; rules: ValidationRuleRequest[] }
      ];
    };
  } = {
    areaId: null,
    businessId: null,
    frequency: null,
    segment: {
      value: null,
      options: []
    },
    rule: {
      value: [],
      options: [
        {
          group: '-- Rules applied to other Time Series in group',
          rules: []
        },
        {
          group: '-- All other Time Series',
          rules: []
        }
      ]
    }
  };

  public created(): void {
    this.$watch(() => this.filter.areaId, this.areaIdChanged);
    this.$watch(() => this.filter.businessId, this.businessIdChanged);
    this.$watch(() => this.filter.frequency, this.frequencyChanged);
  }

  areaIdChanged() {
    this.onFiltersChanged();
  }

  businessIdChanged() {
    this.onFiltersChanged();
  }

  frequencyChanged() {
    this.onFiltersChanged();
  }

  private onFiltersChanged(): Promise<void> {
    if (!this.filter.areaId || !this.filter.businessId || !this.filter.frequency) {
      return Promise.resolve();
    }

    this.filtersLoading = true;
    this.filter.segment.options = [];
    this.filter.segment.value = null;

    return getTimeSeriesEmptyExternalSource(
      this.filter.areaId!,
      this.filter.businessId!,
      this.filter.frequency!
    )
      .then(response => {
        this.filter.segment.options = response;
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => {
        this.filtersLoading = false;
      });
  }

  private onSegmentChanged(): void {
    if (!this.filter.segment.value) {
      return;
    }

    this.filtersLoading = true;
    getValidationRulesBySegmentId(this.filter.segment.value.id)
      .then(response => {
        this.filter.rule.options[0].rules = response.top;
        this.filter.rule.options[1].rules = response.bottom;
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'not_found':
            this.showError("Segment doesn't exist");
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => {
        this.filtersLoading = false;
      });
  }

  public show(): void {
    this.reset();
    this.$refs.createAssignRulesModalRef.show();
  }

  public reset(): void {
    this.filter.areaId = null;
    this.filter.businessId = null;
    this.filter.frequency = null;
    this.filter.segment.value = null;
    this.filter.rule.value = [];
  }

  public cancel(): void {
    this.reset();
    this.$refs.createAssignRulesModalRef.hide();
  }

  public submit(): void {
    const validationRuleIds = this.filter.rule.value.map(x => x.id);

    const segmentId = this.filter.segment.value!.id;

    associateValidationRules(segmentId, validationRuleIds)
      .then(response => {
        this.showInfo('New Rule Associations successfully created.');
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'not_found':
            this.showError("Segment dosn't exist");
            break;
          case 'bad_request':
            this.showError(error.message ?? 'Bad Request');
            break;
          case 'conflict':
            this.showInfo(
              'New Rule Associations successfully created. Some Rule Associations already existed.'
            );
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      });
  }

  private showInfo(message: string): void {
    this.$bvModal
      .msgBoxOk(message, {
        title: 'Saving info',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'outline-secondary',
        footerClass: 'p-2',
        okTitle: 'Ok',
        hideHeaderClose: true,
        centered: true
      })
      .then(response => {
        if (response) {
          window.location.reload();
        }
      });
  }

  private showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true
    });
  }
}
