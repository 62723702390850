



































































































import {
  AddValidationRule,
  UpdateValidationRule,
  ValidationRuleType,
  ValidationRuleTypeId
} from '@model/ValidationRule';
import { Component, Prop } from 'vue-property-decorator';

import { ValidationMixin } from '@mixins/ValidationMixin';

import { MBMultiselect, MBTextField, MBTextarea } from '@components/formComponents';
import {
  addValidationRule,
  anyAssociationsWithSegments,
  deleteValidationRule,
  getValidationRule,
  getValidationRuleTypes,
  updateValidationRule
} from '@/api';
import { ApiError } from '@model/ApiError';

@Component({
  components: {
    'mb-text-field': MBTextField,
    'mb-textarea': MBTextarea,
    'mb-multiselect': MBMultiselect
  }
})
export default class CreateUpdateDeletePopUp extends ValidationMixin {
  @Prop({ default: null, type: String }) idValidationRule!: string | null;
  validationRuleTypes: ValidationRuleType[] = [];
  saveLoading = false;
  deletingLoading = false;
  isEditDelete = false;
  pageLoading = true;
  validationRuleTypeLoading = false;

  validationFields: Record<
    'firstParameter' | 'secondParameter' | 'validationRuleName' | 'validationRuleType',
    string
  > = {
    firstParameter: 'First Parameter',
    secondParameter: 'Second Parameter',
    validationRuleName: 'Rule Name',
    validationRuleType: 'Validation Rule Type'
  };

  model: CreateModel = {
    validationRuleType: null,
    validationRuleName: null,
    parameterFirst: null,
    parameterSecond: null
  };

  notDefaultModel: CreateModel = this.model;

  get modelChanged(): boolean {
    return this.isEditDelete
      ? this.model.validationRuleName !== this.notDefaultModel.validationRuleName ||
          this.model.parameterFirst?.toString() !== this.notDefaultModel.parameterFirst?.toString() ||
          this.model.parameterSecond?.toString() !== this.notDefaultModel.parameterSecond?.toString()
      : this.model.validationRuleType !== null ||
          (this.model.validationRuleName !== null && this.model.validationRuleName !== '') ||
          this.model.parameterFirst !== null ||
          this.model.parameterSecond !== null;
  }

  get ruleContent(): string {
    if (this.model.validationRuleType !== null) {
      const x = this.model.parameterFirst !== null ? this.model.parameterFirst.toString() : '';
      const y = this.model.parameterSecond !== null ? this.model.parameterSecond.toString() : '';
      return this.model.validationRuleType.contentTemplate.format([x, y], 'font-weight-bold');
    }
    return '';
  }

  get parameterFirstField(): IField {
    let result: IField = {
      title: '',
      visible: false,
      props: { trim: true, 'no-wheel': true }
    };

    if (this.model.validationRuleType !== null) {
      switch (this.model.validationRuleType.validationRuleTypeId) {
        case ValidationRuleTypeId.FloorAndCeiling:
          result.title = 'Floor';
          result.precision = 3;
          result.props = { ...result.props, step: '0.001' };
          result.visible = true;
          break;
        case ValidationRuleTypeId.RollingAverage:
          result.title = 'Fetch Count (1-500)';
          result.precision = 0;
          result.props = { ...result.props, step: '1' };
          result.visible = true;
          break;
        case ValidationRuleTypeId.LastEntry:
          result.title = 'Percentage (1-100)';
          result.precision = 3;
          result.props = { ...result.props, step: '0.001' };
          result.visible = true;
          break;
      }
    }

    return result;
  }

  get parameterSecondField(): IField {
    let result: IField = {
      title: '',
      visible: false,
      props: { trim: true, 'no-wheel': true }
    };

    if (this.model.validationRuleType !== null) {
      switch (this.model.validationRuleType.validationRuleTypeId) {
        case ValidationRuleTypeId.LastEntry:
          result.visible = false;
          break;
        case ValidationRuleTypeId.FloorAndCeiling:
          result.title = 'Ceiling';
          result.precision = 3;
          result.props = { ...result.props, step: '0.001' };
          result.visible = true;
          break;
        case ValidationRuleTypeId.RollingAverage:
          result.title = 'Percentage (0.01-100)';
          result.precision = 2;
          result.props = { ...result.props, step: '0.01' };
          result.visible = true;
          break;
      }
    }

    return result;
  }

  async created(): Promise<void> {
    // console.log(this.$validator.rules);
    await this.onInit();
    this.$watch(() => this.model.parameterFirst, this.parameterFirstChanged);
    this.$watch(() => this.model.parameterSecond, this.parameterSecondChanged);
    this.$watch(() => this.model.validationRuleName, this.validationRuleNameChanged);
    this.$watch(() => this.model.validationRuleType, this.validationRuleTypeChanged);
  }

  onLoadValidationRuleTypes(): Promise<void> {
    this.validationRuleTypeLoading = true;

    return getValidationRuleTypes()
      .then(validationRuleTypes => {
        this.validationRuleTypes = validationRuleTypes;
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => {
        this.validationRuleTypeLoading = false;
      });
  }

  async onInit(): Promise<void> {
    this.pageLoading = true;
    try {
      if (this.idValidationRule !== null) {
        this.isEditDelete = true;

        let validationRule = await getValidationRule(this.idValidationRule);

        this.model = {
          validationRuleType: validationRule.type,
          validationRuleName: validationRule.name,
          parameterFirst: validationRule.parameterFirst,
          parameterSecond: validationRule.parameterSecond
        };
        this.notDefaultModel = { ...this.model };
      }
    } catch (error) {
      const apiError = error as ApiError;
      switch (apiError.type) {
        case 'not_found':
          this.showError('Validation rule does not exist');
          this.$emit('done', true);
          this.$bvModal.hide('create-validation-rule-pop-up');
          break;
        case 'internal_server_error':
        case 'action_not_found':
        case 'incorrect_payload':
        default:
          this.showError('Internal Server Error');
          break;
      }
    } finally {
      this.pageLoading = false;
    }

    await this.onLoadValidationRuleTypes();
  }

  async onSave(): Promise<void> {
    await this.validateParameterFirst(this.model.parameterFirst);
    await this.validateParameterSecond(this.model.parameterSecond);
    await this.validateValidationRuleName(this.model.validationRuleName);
    await this.validateValidationRuleType(this.model.validationRuleType);

    if (this.anyErrors()) {
      return;
    }

    this.isEditDelete ? await this.update() : await this.add();
  }

  add(): Promise<void> {
    this.saveLoading = true;

    const dto: AddValidationRule = {
      validationRuleName: this.model.validationRuleName!,
      parameterFirst: this.model.parameterFirst,
      parameterSecond: this.model.parameterSecond,
      validationRuleTypeId: this.model.validationRuleType!.validationRuleTypeId
    };

    return addValidationRule(dto)
      .then(_ => {
        this.$emit('done', true);
        this.$toast.success('The data were successfully saved');
        this.$bvModal.hide('create-validation-rule-pop-up');
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'bad_request':
            this.showError(error.message ?? 'Internal Server Error');
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => {
        this.saveLoading = false;
      });
  }

  update(): Promise<void> {
    this.saveLoading = true;

    const dto: UpdateValidationRule = {
      validationRuleName: this.model.validationRuleName!,
      parameterFirst: this.model.parameterFirst,
      parameterSecond: this.model.parameterSecond
    };

    return updateValidationRule(this.idValidationRule!, dto)
      .then(_ => {
        this.$emit('done', true);
        this.$toast.success('The data were successfully saved');
        this.$bvModal.hide('create-validation-rule-pop-up');
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'bad_request':
            this.showError(error.message ?? 'Internal Server Error');
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => {
        this.saveLoading = false;
      });
  }

  async onDelete(): Promise<void> {
    this.deletingLoading = true;
    let isAnyAssociationsWithSegment: boolean = false;
    try {
      await anyAssociationsWithSegments(this.idValidationRule!).then(response => {
        isAnyAssociationsWithSegment = response;
      });
    } catch (error) {
      const apiError = error as ApiError;
      switch (apiError.type) {
        case 'not_found':
          this.showError("Validation rule doesn't exist");
          break;
        case 'internal_server_error':
        case 'action_not_found':
        case 'incorrect_payload':
        default:
          this.showError('Internal Server Error');
          break;
      }

      this.deletingLoading = false;
      return;
    }

    if (
      (isAnyAssociationsWithSegment &&
        (await this.showPrompt(
          'Are you sure you want to delete this Rule? It will no longer be applied to any Segment.'
        ))) ||
      (!isAnyAssociationsWithSegment && (await this.showPrompt('Are you sure you want to delete this Rule?')))
    ) {
      deleteValidationRule(this.idValidationRule!)
        .then(_ => {
          this.$emit('done', true);
          this.$toast.success('The data were successfully deleted');
          this.$bvModal.hide('create-validation-rule-pop-up');
        })
        .catch((error: ApiError) => {
          switch (error.type) {
            case 'not_found':
              this.showError("Validation rule doesn't exist");
              break;
            case 'internal_server_error':
            case 'action_not_found':
            case 'incorrect_payload':
            default:
              this.showError('Internal Server Error');
              break;
          }
        })
        .finally(() => {
          this.deletingLoading = false;
        });
    }
  }

  async onClose(): Promise<void> {
    this.exit();
  }

  async onCancel(): Promise<void> {
    this.exit();
  }

  async exit(): Promise<void> {
    if (await this.readyToExit()) {
      this.removeAllValidationErrors();
      this.$emit('done', false);
      this.$bvModal.hide('create-validation-rule-pop-up');
    }
  }

  async readyToExit(): Promise<boolean> {
    return (
      (this.modelChanged &&
        (await this.showPrompt('You have unsaved data. Are you sure you want to exit?', 'No'))) ||
      !this.modelChanged
    );
  }

  async validateParameterFirst(value: number | null) {
    let fieldName = this.validationFields.firstParameter;
    this.removeError(fieldName);
    if (this.model.validationRuleType !== null) {
      switch (this.model.validationRuleType.validationRuleTypeId) {
        case ValidationRuleTypeId.FloorAndCeiling:
          if (value !== null) {
            await this.moreThan(fieldName, value, { min: 0 });
          }
          break;
        case ValidationRuleTypeId.RollingAverage:
          await this.between(fieldName, value, {
            min: 1,
            max: 500
          });

          await this.required(fieldName, value);
          break;
        case ValidationRuleTypeId.LastEntry:
          await this.between(fieldName, value, {
            min: 1,
            max: 100
          });

          await this.required(fieldName, value);
          break;
      }
    }
  }

  async parameterFirstChanged(value: number | null): Promise<void> {
    await this.validateParameterFirst(value);
  }

  async validateParameterSecond(value: number | null) {
    let fieldName = this.validationFields.secondParameter;
    this.removeError(fieldName);
    if (this.model.validationRuleType !== null) {
      switch (this.model.validationRuleType.validationRuleTypeId) {
        case ValidationRuleTypeId.FloorAndCeiling:
          if (value !== null) {
            await this.moreThan(fieldName, value, { min: 0 });
          }
          break;
        case ValidationRuleTypeId.LastEntry:
          break;
        case ValidationRuleTypeId.RollingAverage:
          await this.between(fieldName, value, {
            min: 0.001,
            max: 100
          });
          await this.required(fieldName, value);
          break;
      }
    }
  }

  async parameterSecondChanged(value: number | null): Promise<void> {
    await this.validateParameterSecond(value);
  }

  async validationRuleTypeChanged(value: ValidationRuleType | null): Promise<void> {
    switch (value?.validationRuleTypeId) {
      case null:
      case ValidationRuleTypeId.FloorAndCeiling:
        this.model.parameterFirst = null;
        this.model.parameterSecond = null;
        break;
      case ValidationRuleTypeId.LastEntry:
        this.model.parameterFirst = 10;
        this.model.parameterSecond = null;
        break;
      case ValidationRuleTypeId.RollingAverage:
        this.model.parameterFirst = 25;
        this.model.parameterSecond = 10;
        break;
    }
    await this.validateValidationRuleType(value);
  }

  async validateValidationRuleType(value: ValidationRuleType | null) {
    let fieldName = this.validationFields.validationRuleType;
    this.removeError(fieldName);

    await this.required(fieldName, value);
  }

  validationRuleNameChanged(value: string | null) {
    this.validateValidationRuleName(value);
  }

  async validateValidationRuleName(value: string | null) {
    let fieldName = this.validationFields.validationRuleName;
    this.removeError(fieldName);

    await this.required(fieldName, value);
  }

  private async showPrompt(message: string, cancelTitle?: string): Promise<boolean> {
    return await this.$bvModal.msgBoxConfirm(message, {
      title: 'Warning',
      headerBgVariant: 'card-header-label',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Yes',
      cancelTitle: cancelTitle ? cancelTitle : 'Cancel',
      hideHeaderClose: true,
      centered: true
    });
  }

  private showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true
    });
  }
}

interface CreateModel {
  validationRuleType: ValidationRuleType | null;
  validationRuleName: string | null;
  parameterFirst: number | null;
  parameterSecond: number | null;
}

interface IField {
  title: string;
  precision?: number;
  visible: boolean;
  props: object;
}
