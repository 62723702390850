





















import { ANY_IMPORT_SOURCE } from "@model/Filters";
import { ImportSource } from "@model/ImportData";
import { Component, Vue, Model, Watch, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class TimeSeriesImportSourceSelect extends Vue {
  @Prop({ default: false, type: Boolean }) allowAny!: boolean;

  @Model("change", { default: null })
  selectedImportSource!: ImportSource | null;

  options: ImportSource[] = [];
  isLoading = false;

  @Watch("$store.state.filters", { deep: true })
  updateData() {
    this.isLoading = this.$store.state.filters.isLoading;
    const result = [...this.$store.state.filters.importSources];
    if (this.allowAny) {
      result.unshift(ANY_IMPORT_SOURCE);
    }

    this.options = result;
  }

  updateSelected(option: ImportSource) {
    this.$emit("change", option);
  }

  created() {
    this.updateData();
  }
}
