



































































































































import { Component } from 'vue-property-decorator';
import { MbTableMixin } from '@mixins/MbTableMixin';
import CreateAssignRulesModal from '../components/CreateAssignRulesModal.vue';
import { AxiosError } from 'axios';
import { transformToVueTableFormat } from '@model/tools';
import { PaginationParametersDto, SortingParametersDto } from '@services/dtos/common';
import TablePagination from '@components/TablePagination.vue';
import { SortParameter, toSortingParameters } from '@model/Table';
import { debounce, isEmpty } from 'lodash-es';
import { ApiError, toApiError } from '@model/ApiError';
import { getSegments, getSegmentsContents, removeAssociation } from '@/api/validation-rules';
import { IdNamePair } from '@model/Common';
import { getCurrentUser } from '@/api/users';
import {User} from "@model/User";
import AccessDenied from "@views/access-denied.vue";
import ContentLoading from "@views/content-loading.vue";

@Component({
  components: {
    ContentLoading,
    'access-denied': AccessDenied,
    CreateAssignRulesModal,
    'table-pagination': TablePagination
  }
})
export default class AssignRulesTimeSeries extends MbTableMixin {
  pageLoading = false;
  fetchingData = false;
  pagination: PaginationParametersDto = {
    perPage: 10,
    page: 1
  };
  public currentUser: User = { isAdmin: false, name: '', isActive: false, userId: '', email: '' };
  setFirstPageAndRefreshTableLazy = debounce(this.setFirstPageAndRefreshTable, 1000);

  setFirstPageAndRefreshTable() {
    this.pagination.page = 1;
    this.refreshTable();
  }

  totalCount = 0;
  public tableSettings = {
    apiUrl: '/web/validationRules/filtered',
    fields: [
      {
        name: 'areaName',
        title: 'Area',
        sortField: 'area',
        titleClass: 'sorting'
      },
      {
        name: 'businessName',
        title: 'Business',
        sortField: 'business',
        titleClass: 'sorting'
      },
      {
        name: 'frequencyName',
        title: 'Periods',
        sortField: 'frequency',
        titleClass: 'sorting'
      },
      {
        name: 'segmentName',
        title: 'Time Series',
        sortField: 'timeSeries',
        titleClass: 'sorting'
      },
      {
        name: 'validationRuleContent',
        title: 'Rule Content',
        sortField: 'ruleContent',
        titleClass: 'sorting'
      },
      {
        name: 'validationRuleName',
        title: 'Rule Description',
        sortField: 'ruleDescription',
        titleClass: 'sorting'
      },
      {
        name: 'action-slot',
        title: 'Actions'
      }
    ]
  };

  periodsFilters: {
    areaIds: number[];
    businessIds: number[];
    frequencyIds: number[];
  } = {
    areaIds: [],
    businessIds: [],
    frequencyIds: []
  };

  public filter: {
    segment: {
      value: IdNamePair<number>[];
      options: IdNamePair<number>[];
    };
    ruleContentType: {
      value: IdNamePair<string>[];
      options: IdNamePair<string>[];
    };
    ruleDescription: string | null;
  } = {
    segment: {
      value: [],
      options: []
    },
    ruleContentType: {
      value: [],
      options: []
    },
    ruleDescription: null
  };

  beforeMount() {
    this.pageLoading = true;
  }
  async created(): Promise<void> {
    this.currentUser = await getCurrentUser();
    await this.getSegments();
    await this.getSegmentsContents();
    this.pageLoading = false;
  }

  getSegments(): Promise<void> {
    return getSegments()
      .then(response => {
        this.filter.segment.options = response;
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      });
  }

  getSegmentsContents(): Promise<void> {
    return getSegmentsContents()
      .then(response => {
        this.filter.ruleContentType.options = response;
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      });
  }

  errorOccured(error: AxiosError) {
    if (process.env.NODE_ENV !== 'production') {
      console.dir(error);
    }
    const apiError = toApiError(error);
    switch (apiError.type) {
      case 'internal_server_error':
      case 'action_not_found':
      case 'incorrect_payload':
      default:
        this.showError('Internal Server Error');
        break;
    }
  }

  transformResponse(response: ValidationRulesTimeSeriesSearchResultDto) {
    this.totalCount = response.totalCount;
    return transformToVueTableFormat(response);
  }

  makeQueryParams(sortOrder: SortParameter[]): ValidationRulesTimeSeriesParametersDto {
    const segmentIds = this.filter.segment.value.map(x => x.id);
    const validationRuleIds = this.filter.ruleContentType.value.map(x => x.id);

    const filters: Partial<ValidationRulesTimeSeriesFilterDto> = {
      areaIds: isEmpty(this.periodsFilters.areaIds) ? undefined : this.periodsFilters.areaIds,
      businessIds: isEmpty(this.periodsFilters.businessIds) ? undefined : this.periodsFilters.businessIds,
      frequencyIds: isEmpty(this.periodsFilters.frequencyIds) ? undefined : this.periodsFilters.frequencyIds,
      segmentIds: isEmpty(segmentIds) ? undefined : segmentIds,
      validationRuleIds: isEmpty(validationRuleIds) ? undefined : validationRuleIds,
      validationRuleName: isEmpty(this.filter.ruleDescription) ? undefined : this.filter.ruleDescription
    };

    const query: ValidationRulesTimeSeriesParametersDto = {
      pagination: {
        page: this.pagination.page,
        perPage: this.pagination.perPage
      },
      filter: Object.values(filters).every(el => el === undefined) ? null : filters,
      sorting: sortOrder.length ? toSortingParameters(sortOrder[0]) : null
    };

    return query;
  }

  paginationChanged() {
    this.refreshTable();
  }

  deleteItem({ validationRuleId, segmentId }: { validationRuleId: string; segmentId: number }): void {
    this.$bvModal
      .msgBoxConfirm('Are you sure you want to delete this Rule Association?', {
        id: 'delete-modal',
        title: 'Delete Association',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'outline-secondary',
        footerClass: 'p-2',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: false,
        centered: true
      })
      .then(response => {
        if (response) {
          removeAssociation(segmentId, validationRuleId)
            .then(response => {
              this.showInfo();
            })
            .catch((error: ApiError) => {
              switch (error.type) {
                case 'not_found':
                  this.showError(error.message ?? 'Not Found');
                  break;
                case 'internal_server_error':
                case 'action_not_found':
                case 'incorrect_payload':
                default:
                  this.showError('Internal Server Error');
                  break;
              }
            });
        }
      });
  }

  private showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true
    });
  }

  showInfo(): void {
    this.$bvModal
      .msgBoxOk('Association successfully deleted', {
        id: 'info-modal',
        title: 'Saving info',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'outline-secondary',
        footerClass: 'p-2',
        okTitle: 'Ok',
        hideHeaderClose: true,
        centered: true
      })
      .then(response => {
        if (response) {
          this.getSegments();
          this.getSegmentsContents();
          this.refreshTable();
        }
      });
  }
}

interface ValidationRulesTimeSeriesParametersDto {
  filter: Partial<ValidationRulesTimeSeriesFilterDto> | null;
  pagination: PaginationParametersDto;
  sorting: SortingParametersDto | null;
}

interface ValidationRulesTimeSeriesFilterDto {
  areaIds: number[];
  businessIds: number[];
  frequencyIds: number[];
  segmentIds: number[];
  validationRuleIds: string[];
  validationRuleName: string | null;
}

interface ValidationRulesTimeSeriesSearchResultDto {
  totalCount: number;
  page: number;
  perPage: number;
  entries: TimeSeriesWithValidationRuleDto[];
}

interface TimeSeriesWithValidationRuleDto {
  segmentId: number;
  areaName: string;
  businessName: string;
  frequencyName: string;
  segmentName: string;
  validationRuleName: string;
  validationRuleContent: string;
}
