















import { Component, Vue } from 'vue-property-decorator';
import NewManualTimeSeries from './new-manual-time-series.vue';
import ExistingManualTimeSeries from './existing-manual-time-series.vue';
import { getAllAreas, getAllBusinesses, getAllAdUsers, getAllDataOwners, getAllPeriods, getAllSizes, getCurrentUser } from '@/api';
import {User} from "@model/User";
import AccessDenied from "@views/access-denied.vue";
import ContentLoading from "@views/content-loading.vue";

Component.registerHooks(['beforeRouteLeave']);
@Component({
  components: {
    ContentLoading,
    'access-denied': AccessDenied,
    'new-time-series': NewManualTimeSeries,
    'existing-time-series': ExistingManualTimeSeries
  }
})
export default class TimeSeriesManual extends Vue {
  public currentUser: User = { isAdmin: false, name: '', isActive: false, userId: '', email: '' };
  pageLoading = false;
  get existingTimeSeriesTable(): ExistingManualTimeSeries {
    return this.$refs.existingTimeSeriesTable as ExistingManualTimeSeries;
  }

  get newTimeSeriesTable(): NewManualTimeSeries {
    return this.$refs.newTimeSeriesTable as NewManualTimeSeries;
  }

  get hasUnsavedChanges(): boolean {
    return this.existingTimeSeriesTable.hasUnsavedChanges || this.newTimeSeriesTable.hasUnsavedChanges;
  }

  beforeMount() {
    this.pageLoading = true;
  }
  async created() {
    this.currentUser = await getCurrentUser();
    await this.restoreCommonData();

    window.addEventListener('beforeunload', this.onBeforeUnload);
    this.pageLoading = false;
  }

  onBeforeUnload(event: any) {
    // User is closing tab/browser. If there are unsaved changes, have the browser prompt for confirmation
    if (this.hasUnsavedChanges) {
      event.preventDefault();
    }
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
  }

  beforeRouteLeave(to, from, next) {
    // User is moving to another page within the app. If there are unsaved changes, prompt for confirmation
    // const hasUnsavedValues = this.statusChanges.length > 0;
    if (!this.hasUnsavedChanges) {
      next();
      return;
    }

    const canLeave = window.confirm('You have unsaved changes. Do you wish to leave this page?');

    next(canLeave);
  }

  private restoreCommonData() {
    const restoreAreas = getAllAreas().then(data => this.$store.commit('areasReceived', data));

    const restoreBusinesses = getAllBusinesses().then(data => this.$store.commit('businessesReceived', data));

    const restorePeriods = getAllPeriods().then(data => this.$store.commit('periodsReceived', data));

    const restoreDataOwners = getAllDataOwners().then(data => this.$store.commit('dataOwnersReceived', data));

    const restoreUsers = getAllAdUsers().then(data => this.$store.commit('usersReceived', data));

    const restoreSizes = getAllSizes().then(data => this.$store.commit('sizesReceived', data));

    return Promise.all([restoreAreas, restoreBusinesses, restorePeriods, restoreUsers, restoreDataOwners, restoreSizes]);
  }
}
