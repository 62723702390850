











import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator';
@Component({
  components: {},
  inheritAttrs: false,
})
export default class MTextarea extends Vue {
  @ModelSync('value', 'input', { type: String }) modelText!: string | null;

  @Prop({ default: false, type: Boolean })
  showErrorMessage!: boolean;
  @Prop({ default: [], type: Array })
  errorMessages!: string[];
}
