export interface Envelope {
    result: string;
    message: string;
    timeGenerated: Date;
    validationErrors: IModelError[];
    errorMessage: string;
  }
  
  export interface IEnvelope<T> {
    result: T;
    message: string;
    timeGenerated: Date;
    validationErrors: IModelError[];
    errorMessage: string;
  }
  
  export interface IModelError {
    key: string;
    errorMessages: string[];
  }

  export enum Unit {
    UsdPerDay = 1,
    MioUsd = 2,
    UsdPerLdt = 3
  }