import { IEnvelope, IResponseModel } from "@model/Common";
import axios from "axios";
import dayjs from "dayjs";
import Vue from "vue";
import debounce from "lodash/debounce";
import assignIn from "lodash/assignIn";
import { Mixin } from "vue-mixin-decorator";

@Mixin
export class MbTableMixin extends Vue {
  protected filter: any = {
    text: null
  };
  protected fetchingData: boolean = false;
  protected perPage: number = 10;
  protected perPageOptions: number[] = [10, 25, 50, 100];
  protected css: any = {
    table: {
      tableWrapper: "",
      tableHeaderClass: "mb-0",
      tableBodyClass: "mb-0",
      tableClass: "table b-table table-bordered table-sm b-table-stacked-md",
      loadingClass: "loading",
      ascendingClass: "sorting sorting_asc",
      descendingClass: "sorting sorting_desc",
      detailRowClass: "vuetable-detail-row",
      handleIcon: "fa fa-bars text-secondary",
      renderIcon: (classes: string[]) =>
        `<i class="${classes.join(" ")}"></span>`
    }
  };

  get searchSettings(): any {
    return {
      columns: [],
      ranges: []
    };
  }

  protected saveFilters() {
    // const filterString = JSON.stringify(this.filter);
    // sessionStorage.setItem(`${this.$root.$el.baseURI}_${this.$vnode.tag}_filters`, filterString);
  }

  private _filtersLoaded: boolean = false;
  protected loadFilters() {
    if (!this._filtersLoaded) {
      const existingFilters = sessionStorage.getItem(`${this.$root.$el.baseURI}_${this.$vnode.tag}_filters`);
      if (existingFilters) {
        assignIn(this.filter, JSON.parse(existingFilters));
      }
    }

    this._filtersLoaded = true;
  }

  private _filtersFromStorage: any;
  protected getFilterValueOrDefault(field: string, defaultValue: any = null) {
    if (this._filtersFromStorage) {
      return this._filtersFromStorage[field];
    } else {
      const existingFilters = sessionStorage.getItem(`${this.$root.$el.baseURI}_${this.$vnode.tag}_filters`);
      if (existingFilters) {
        this._filtersFromStorage = JSON.parse(existingFilters);
        return this._filtersFromStorage[field];
      } else {
        return defaultValue;
      }
    }
  }

  protected toggleLoader(state: boolean) {
    if (state === true) {
      this.loadFilters();
    }

    this.fetchingData = state;
    this.setDataLabels();
  }
  protected resetFilters() {
    this.filter = { text: "" };
    (this.$refs.vuetable as any).refresh();
  }

  protected refreshData() {
    (this.$refs.vuetable as any).resetData();
    this.resetFilters();
  }

  protected refreshTable() {
    this.saveFilters();
    if (this.$refs.vuetable) {
      (this.$refs.vuetable as any).refresh();
    }
  }

  protected filterFunctions = {
    setTextFilter: this.setTextFilter,
    setRangeFromFilter: this.setRangeFromFilter,
    setRangeToFilter: this.setRangeToFilter
  };

  private setTextFilter(newValue: string, fieldName: string, getFilterRef: () => object) {
    if (getFilterRef) {
      const filter = getFilterRef();
      Vue.set(filter, fieldName, newValue);
      this.applyFilters();
    }
  }

  private setRangeFromFilter(fromValue: any, fieldName: string, getFilterRef: () => object) {
    if (getFilterRef) {
      const filter = getFilterRef();
      Vue.set(filter, fieldName, {
        from: fromValue,
        to: filter[fieldName].to
      });
      this.applyFilters();
    }
  }

  private setRangeToFilter(toValue: any, fieldName: string, getFilterRef: () => object) {
    if (getFilterRef) {
      const filter = getFilterRef();
      Vue.set(filter, fieldName, {
        from: filter[fieldName].from,
        to: toValue
      });
      this.applyFilters();
    }
  }

  protected applyFilters = debounce(this.refreshTable, 250);

  protected transform<T>(data: IEnvelope<IResponseModel<T>>) {
    return data.result;
  }

  protected fetch<T>(apiUrl: string) {
    return axios.post<IEnvelope<IResponseModel<T>>>(
      apiUrl,
      this.buildFilterParams()
    );
  }

  protected onPaginationData(paginationData: any) {
      (this.$refs.pagination as any).setPaginationData(paginationData);
      (this.$refs.paginationInfo as any).setPaginationData(paginationData);
  }

  protected onChangePage(page: number) {
    (this.$refs.vuetable as any).changePage(page);
  }

  protected buildFilterParams() {
    const httpOptions = (this.$refs.vuetable as any).httpOptions;
    const data = {
      draw: 1,
      start: (httpOptions.params.page - 1) * httpOptions.params.per_page,
      length: httpOptions.params.per_page,
      columns: [...this.searchSettings.columns],
      search: {
        value: this.filter.text,
        regex: false
      },
      order: [],
      searchRanges: [...this.searchSettings.ranges]
    };
    if (httpOptions.params.sort) {
      data.order = httpOptions.params.sort.split(",").map((order: string) => {
        const item = order.split("|");
        return {
          column: item[0],
          dir: item[1]
        };
      });
    }

    return data;
  }

  protected sortCompare(a: any, b: any, key: string) {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      // If both compared fields are native numbers
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    }

    const valueA = (this.$root.$options.filters as any).fromNumberString(
      a[key]
    );
    const valueB = (this.$root.$options.filters as any).fromNumberString(
      b[key]
    );
    if (valueA !== null && valueB !== null) {
      return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    }

    // If date
    if (dayjs(a[key]).isValid() && dayjs(b[key]).isValid()) {
      return dayjs(a[key]).isBefore(dayjs(b[key]))
        ? -1
        : dayjs(a[key]).isAfter(dayjs(b[key]))
          ? 1
          : 0;
    }

    // Stringify the field data and use String.localeCompare
    return this.toString(a[key]).localeCompare(
      this.toString(b[key]),
      undefined,
      {
        numeric: true
      }
    );
  }

  protected setDataLabels() {
    const vuetable = this.$refs.vuetable as any;
    if (vuetable) {
      let rows = vuetable.$el
        .querySelector("tbody")
        .querySelectorAll("tr");
      if (rows.length) {
        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
          for (let columnIndex = 0; columnIndex < rows[rowIndex].children.length; columnIndex++) {
            if (vuetable.fields[columnIndex] && vuetable.fields[columnIndex].title !== "Actions") {
              rows[rowIndex].children[columnIndex].setAttribute('data-label', vuetable.fields[columnIndex].title);
            }
          }
        }
      }
    }
  }

  protected toString(v: any): string {
    if (!v) {
      return "";
    }
    if (v instanceof Object) {
      return Object.keys(v)
        .map(k => this.toString(v[k]))
        .join(" ");
    }
    return String(v);
  }

}
