
































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { eventBus } from '@/main';
import { TimeSerieGlobalFilter } from '@model/TimeSerie';
import { getAllAreas, getAllBusinesses, getAllPeriods } from '@/api';

@Component({})
export default class TimeSeriesFilters extends Vue {
  public selectedAreaId: number | null = null;

  public selectedBusinessId: number | null = null;

  public selectedFrequencyId: number | null = null;

  private isOverdueTimeSeriesOnly: boolean = false;

  readonly optionsOverdueTimeSeriesFilter = [
    { text: 'Yes', value: true },
    { text: 'No', value: false }
  ];

  get contextFilters(): TimeSerieGlobalFilter | null {
    return this.$store.state.filtersContext.filters;
  }

  public applyFilterClick() {
    this.$store.commit('setFilters', {
      areaId: this.selectedAreaId,
      businessId: this.selectedBusinessId,
      frequencyId: this.selectedFrequencyId,
      isOverdueTimeSeriesOnly: this.isOverdueTimeSeriesOnly ? true : null
    } as TimeSerieGlobalFilter);

    eventBus.$emit('appliedFilters');
  }

  private restoreCommonData() {
    const restoreAreas = getAllAreas().then(data => this.$store.commit('areasReceived', data));

    const restoreBusinesses = getAllBusinesses().then(data => this.$store.commit('businessesReceived', data));

    const restorePeriods = getAllPeriods().then(data => this.$store.commit('periodsReceived', data));

    return Promise.all([restoreAreas, restoreBusinesses, restorePeriods]);
  }

  async created(): Promise<void> {
    await this.restoreCommonData();
    this.setDefaultFilters();

    this.$watch(() => this.contextFilters, this.onContextFiltersChanged);
  }

  setDefaultFilters(): void {
    this.selectedAreaId = null;
    this.selectedBusinessId = null;
    this.selectedFrequencyId = null;
    this.isOverdueTimeSeriesOnly = false;
    this.$store.commit('setFilters', null);
  }

  onContextFiltersChanged(): void {
    this.resetFilters();
  }

  resetFilters() {
    if (this.contextFilters === null) this.setDefaultFilters();
  }
}
