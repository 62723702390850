declare global {
    interface String {
        /**
         * Returns formatted string with injected values. works like in c# string.format('string with {0}', ...params) but here instead of ...params, array with values
         * @param values array with values to inject.
         * @param class Optional. if you want to make text bold, underscore etc.
         */
        format(values: (string | number)[], injectedTextClass?: string): string;
    }
}

String.prototype.format = function (values: (string | number)[], injectedTextClass?: string) {
    return String(this).replace(/{(\d+)}/g, (match: string, number: string | number) => {
        return values[number] !== undefined
            ? injectedTextClass !== undefined ? `<span class='${injectedTextClass}''>${values[number]}</span>` : values[number]
            : match
            ;
    });
};

export { }