











import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'mbv-loader',
})
export default class NotificationModal extends Vue {
    @Prop({ default: false }) public isLoading!: boolean;
    @Prop({ default: 'Loading...' }) public loadingText!: string;
}
