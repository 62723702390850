













import { Component, Vue, Model } from "vue-property-decorator";


@Component({
  components: {},
})
export default class MBSegmentNameInput extends Vue {

  @Model('change', { default: null }) value!: string | null;

  updateSelected(value: string) {
    this.$emit('change', value);
  }
}

