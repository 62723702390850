


















import { Component, Vue, Model } from 'vue-property-decorator';

type SelectOption = 'Active' | 'Inactive';
const ACTIVE: SelectOption = 'Active';
const INACTIVE: SelectOption = 'Inactive';

@Component({
  components: {}
})
export default class TimeSerieStatusSelect extends Vue {
  @Model('change', { default: true, type: Boolean }) value!: boolean | null;

  get controlValue(): SelectOption {
    return this.value ? ACTIVE : INACTIVE;
  }

  options: SelectOption[] = [INACTIVE, ACTIVE];

  onChanged(value: string) {
    this.$emit('change', value === ACTIVE);
  }
}
