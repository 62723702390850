import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

import MBAreaSelect from '@components/mb-area-select.vue';
import MBBusinessSelect from '@components/mb-business-select.vue';
import MBDataOwnerSelect from '@components/mb-data-owner-select.vue';
import MBFrequencySelect from '@components/mb-frequency-select.vue';
import MBSegmentIdInput from '@components/mb-segment-id-input.vue';
import MBSegmentNameInput from '@components/mb-segment-name-input.vue';
import MBSortOrderInput from '@components/mb-sort-order-input.vue';
import MBStatusSelect from '@components/mb-status-select.vue';
import MBTimeSeriesImportCodeInput from '@components/mb-time-series-import-code-input.vue';
import MBTimeSeriesImportSourceSelect from '@components/mb-time-series-import-source-select.vue';
import Pagination from '@components/Pagination.vue';
import PaginationInfoWithFilter from '@components/PaginationInfoWithFilter.vue';
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import VueFlatpickr from 'vue-flatpickr-component';
import Multiselect from 'vue-multiselect';
import {
  Vuetable,
  VuetableColGutter,
  VuetableFieldCheckbox,
  VuetableFieldHandle,
  VuetableFieldSequence,
  VuetableRowHeader
} from 'vuetable-2';
import MBSizeSelect from './components/mb-size-select.vue';

export default function () {
  Vue.use(BootstrapVue);

  Vue.component('multiselect', Multiselect);
  Vue.component('flat-pickr', VueFlatpickr);

  Vue.component('vuetable', Vuetable);
  Vue.component('vuetable-col-gutter', VuetableColGutter);
  Vue.component('vuetable-field-checkbox', VuetableFieldCheckbox);
  Vue.component('vuetable-field-handle', VuetableFieldHandle);
  Vue.component('vuetable-field-sequence', VuetableFieldSequence);
  Vue.component('vuetable-row-header', VuetableRowHeader);
  Vue.component('vuetable-pagination-info-with-filter', PaginationInfoWithFilter);
  Vue.component('vuetable-pagination-bootstrap', Pagination);

  Vue.component('mb-area-select', MBAreaSelect);
  Vue.component('mb-business-select', MBBusinessSelect);
  Vue.component('mb-frequency-select', MBFrequencySelect);
  Vue.component('mb-sort-order-input', MBSortOrderInput);
  Vue.component('mb-status-select', MBStatusSelect);
  Vue.component('mb-data-owner-select', MBDataOwnerSelect);
  Vue.component('mb-size-select', MBSizeSelect);
  Vue.component('mb-segment-name-input', MBSegmentNameInput);
  Vue.component('mb-segment-id-input', MBSegmentIdInput);
  Vue.component('mb-time-series-import-code-input', MBTimeSeriesImportCodeInput);
  Vue.component('mb-time-series-import-source-select', MBTimeSeriesImportSourceSelect);
}
