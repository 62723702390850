





















import { ANY_AREA } from "@model/Filters";
import { Component, Vue, Prop, Model, Watch } from "vue-property-decorator";

interface TimeSeriesArea {
  id: number;
  name: string;
}

@Component({
  components: {},
})
export default class TimeSeriesAreaSelect extends Vue {
  @Prop({ default: false, type: Boolean }) allowAny!: boolean;
  @Model("change", { default: null }) value!: TimeSeriesArea | number | null;

  get controlValue(): TimeSeriesArea | null {

    if (!this.value) {
      return this.allowAny ? ANY_AREA : null;
    }

    return typeof this.value === "number"
      ? this.options.find((x) => x.id === this.value) ?? null
      : this.value;
  }

  options: TimeSeriesArea[] = [ANY_AREA];

  isLoading = false;

  @Watch("$store.state.filters", { deep: true })
  updateData() {
    this.isLoading = this.$store.state.filters.isLoading;
    const result = [...this.$store.state.filters.areas];

    if (this.allowAny) {
      result.unshift(ANY_AREA);
    }

    this.options = result;
  }

  updateSelected(option: TimeSeriesArea) {
    this.$emit("change", option.id);
  }

  created() {
    this.updateData();
  }
}
