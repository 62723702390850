import { PaginationParameters, SortingParameters } from './Common';

export interface SortParameter {
  direction: "asc" | "desc";
  field: string;
  sortField: string;
}

export interface PaginationParameter {
  current_page: number;
  from: number;
  last_page: number;
  next_page_url: number;
  per_page: number;
  prev_page_url: number;
  to: number;
  total: number;
}

export function toPaginationParameters(value: PaginationParameter): PaginationParameters {
  return {
    page: value.current_page,
    perPage: value.per_page
  };
}

export function toSortingParameters(value: SortParameter): SortingParameters {
  return {
    sortBy: value.sortField,
    sortOrder: value.direction
  };
}
