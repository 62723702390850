import { ImportSource } from '@model/ImportData';
import { ImportResultDto } from '@services/dtos/time-series';
import axios from 'axios';

export async function testImport(importSource: string, importCode: string): Promise<ImportResultDto> {
  const response = await axios.get<ImportResultDto>(`/api/import/test?sourceName=${importSource}&code=${importCode}`);
  return response.data;  
}

export function getAllImportSources(): Promise<ImportSource[]> {
  return axios.get<ImportSource[]>('/api/import/sources').then(response => response.data);
}
