











































































































import { Component, Vue } from 'vue-property-decorator';
import { isNil, isEmpty, trim } from 'lodash-es';

import { getAreaName, getBusinessName, getUserName, getPeriodName } from '@model/Common';
import { ManualTimeSeriesEntryDto } from '@services/dtos/time-series';
import { ApiError } from '@model/ApiError';
import { addNewManualTimeSeries } from '@/api';

@Component({
  components: {}
})
export default class NewManualTimeSeries extends Vue {
  isAddingNewSeriesItem = false;

  newTimeSeries: ManualTimeSeriesEntryDto[] = [];

  areaId: number | null = null;
  businessId: number | null = null;
  frequencyId: number | null = null;
  segmentName: string | null = null;
  dataOwnerId: string | null = null;
  sizeId: string | null = null;
  createMonthly: boolean = false;

  isProduction = process.env.NODE_ENV === 'production';

  get hasUnsavedChanges() {
    return (
      this.isAddingNewSeriesItem ||
      !isNil(this.areaId) ||
      !isNil(this.businessId) ||
      !isNil(this.frequencyId) ||
      !isEmpty(trim(this.segmentName ?? '')) ||
      !isNil(this.dataOwnerId)
    );
  }

  public get frequencyIdIsWeekly(): boolean {
      return this.frequencyId === 2;
  }

  get canAddNewSeriesItem(): boolean {
    const checks = [
      !this.isAddingNewSeriesItem,
      !isNil(this.areaId),
      !isNil(this.businessId),
      !isNil(this.frequencyId),
      !isNil(this.dataOwnerId)
    ];

    return checks.every(passed => passed);
  }

  toAreaName(id: number) {
    return getAreaName(this.$store.state.areas, id);
  }

  toBusinessName(id: number) {
    return getBusinessName(this.$store.state.businesses, id);
  }

  toPeriodName(id: number) {
    return getPeriodName(this.$store.state.periods, id);
  }

  toDataOwnerName(id: string) {
    return getUserName(this.$store.state.users, id);
  }

  onAddTimeSeriesItemClick() {
    if (!this.canAddNewSeriesItem) {
      return;
    }

    this.isAddingNewSeriesItem = true;

    addNewManualTimeSeries({
      areaId: this.areaId!,
      businessId: this.businessId!,
      name: this.segmentName,
      frequency: this.frequencyId!,
      dataOwnerUserId: this.dataOwnerId!,
      sizeId: this.sizeId,
      createMonthly: this.createMonthly
    })
      .then(result => {
        this.resetInsertedTimeSerieValues();
        this.$toast.success('The data were successfully saved');
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'validation_error':
            let allValidationErrorMessages: string[] = [];
            for (const key in error.errors) {
              allValidationErrorMessages.push(error.errors[key][0]);
            }
            this.showError(allValidationErrorMessages.join('\n'), true);
            break;
          case 'bad_request':
            this.showError(error.message ?? 'Bad request');
            break;
          case 'conflict':
            const errorMessage = error.response as string;
            this.showError(errorMessage);
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => (this.isAddingNewSeriesItem = false));
  }

  private resetInsertedTimeSerieValues() {
    this.areaId = null;
    this.businessId = null;
    this.frequencyId = null;
    this.segmentName = null;
    this.dataOwnerId = null;
    this.sizeId = null;
  }

  private showError(message: string, newLine: boolean = false): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: newLine ? 'md' : 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true,
      bodyClass: newLine ? 'body-new-line' : ''
    });
  }
}
