






























import { Component, Prop, Vue } from "vue-property-decorator";
import { Carousel, Slide } from "vue-carousel";
import { IRouteLinkParam } from "@model/Common";
@Component({
  components: { carousel: Carousel, slide: Slide },
})
export default class Tabs extends Vue {
  @Prop({ default: () => [], type: Array }) routeLinkParams!: IRouteLinkParam[];
  navigateTo: number = 0;

  //maximum 3 per page
  perPageCustom: IPerPageCustom[] = [
    [1, 1],
    [1100, 1],
    [1350, 2],
    [1500, 3],
  ];

  adjustableHeight = true;

  // I want to scroll to the active tab
  created() {
    this.$watch(() => this.$route, this.navigateCarousel, {
      immediate: true,
    });
  }

  // I want to have heights adjustable on zoom
  mounted() {
    this.adjustableHeight = false;
  }

  navigateCarousel() {
    const rlpIndex = this.routeLinkParams.findIndex(
      (rlp) => rlp.to === this.$route.path
    );
    if (rlpIndex !== -1) {
      if (rlpIndex === this.routeLinkParams.length - 1) {
        this.navigateTo = rlpIndex - 2;
      } else if (rlpIndex === 0) {
        this.navigateTo = rlpIndex;
      } else {
        this.navigateTo = rlpIndex - 1;
      }
    }
  }
}

// [width, perPage]
export type IPerPageCustom = [number, number];
