import { User, CreateNewUser, TimeSeriesDataProviders } from '@model/User';
import { SubstituteUser } from '@services/dtos/users';
import axios, {AxiosPromise, AxiosResponse} from 'axios';

let userTask: AxiosPromise<User>|null = null;
export async function getCurrentUser(): Promise<User> {

  if(userTask === null) {
    userTask = axios.get<User>('/web/users/current');
  }
  const response = await userTask;
  return response.data;
  
}

export function getAllUsers(): Promise<User[]> {
  return axios.get<User[]>('/web/users/all').then(x => x.data);
}

export function getAllAndAssignedUsers(): Promise<User[]> {
  return axios.get<User[]>('/web/users/allAndAssigned').then(x => x.data);
}

export function addUser(name: string, email: string, isAdmin: boolean) {
  const user: CreateNewUser = {
    name: name,
    email: email,
    isAdmin: isAdmin
  };

  return axios.post('/web/users', user);
}

export async function setUserSegments(dataproviders: TimeSeriesDataProviders[]): Promise<AxiosResponse<void>> {
  return axios.post(`/web/userrightsuser/add-data-providers`, dataproviders);
}

export function deleteDataProvider(segmentId: number, userId: string) {
  return axios.delete(`/web/userrightsuser/segments/${segmentId}/dataProviders/${userId}/remove`, {});
}

export function deleteAllDataProvider(userId: string) {
  return axios.delete(`/web/userrightsuser/segments/removeallsegments/dataProviders/${userId}/remove`, {});
}

export function substituteDataOwner(segmentId: number, userId: string) {
  return axios.post(`/web/userrightsuser/segments/${segmentId}/substituteDataOwner/${userId}`, {});
}

export function substituteDataProvider(segmentId: number, userIdToAdd: string, userIdToRemove: string) {
  return axios.post(`/web/userrightsuser/segments/${segmentId}/substituteDataProvider`, {
    toAdd: userIdToAdd,
    toRemove: userIdToRemove
  } as SubstituteUser);
}

export function updateAdmins(toUnsetUserIds: string[], toSetUserIds: string[]) {
  return axios.post('/web/users/updateAdmins', {
    toUnsetUserIds,
    toSetUserIds
  });
}
