




















import { ANY_IMPORT_SOURCE } from '@model/Filters';
import { ImportSource } from '@model/ImportData';
import { Component, Vue, Model, Watch, Prop } from 'vue-property-decorator';

interface SelectOption {
  key: string;
  name: string;
}

@Component({
  components: {}
})
export default class MBTimeSeriesImportSourceSelect extends Vue {
  @Prop({ default: false, type: Boolean }) allowAny!: boolean;
  @Model('change', { default: null }) value!: SelectOption | string | null;

  get options(): SelectOption[] {
    const result: SelectOption[] = [];

    if (this.allowAny) {
      result.push(ANY_IMPORT_SOURCE);
    }

    this.$store.state.importSources.forEach((a: ImportSource) => result.push(a));

    return result;
  }

  get controlValue(): SelectOption | null {
    if (!this.value) {
      return this.allowAny ? ANY_IMPORT_SOURCE : null;
    }

    return typeof this.value === 'string' ? this.options.find(x => x.key === this.value) ?? null : this.value;
  }

  updateSelected(option: SelectOption) {
    this.$emit('change', option.key);
  }
}
