import dayjs from 'dayjs';

import { Unit } from './model';

export function formatForUnit(value: any, unit: number): string | null {
  if (isNaN(value)) {
    return null;
  }

  value = parseFloat(value);

  switch (unit) {
    case Unit.MioUsd:
      // between 1 and 3 digits
      return value.toLocaleString('en-GB', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 3,
        useGrouping: true
      });
    case Unit.UsdPerDay:
      // No digits
      return value.toLocaleString('en-GB', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: true
      });
    case Unit.UsdPerLdt:
      // Always two digits
      return value.toLocaleString('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true
      });
  }
  return null;
}

export function formatToFixedPrecision(value: any): string | null {
  
  if (isNaN(value)) {
    return null;
  }
  
  let splitValues = value.split('.');
  let minimumFractionDigits = 0;

  value = parseFloat(value);

  if (splitValues.length > 1 && splitValues[1].length > minimumFractionDigits) {
    minimumFractionDigits = splitValues[1].length;
  }

  return value.toLocaleString('en-GB', {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: minimumFractionDigits,
    useGrouping: true
  });
}

export function addUserToLocalStorage(value: string): void {
  if (value === null || value === undefined) {
    return;
  }
  let existingEntries = localStorage.getItem('allUsers');

  if (existingEntries == null) {
    existingEntries = value;
  }

  let users = existingEntries.split(',');
  let newEntries = Array<String>();

  newEntries.push(value);

  users.forEach((item, index) => {
    if (item !== value && index < 9) {
      newEntries.push(item);
    }
  });

  localStorage.setItem('allUsers', newEntries.toString());
}

export function getUsersFromLocalStorage(): string[] {
  let existingEntries = localStorage.getItem('allUsers');

  return existingEntries ? existingEntries.split(',') : [];
}

export function formatDate(value: Date | number) {
  return dayjs(value).format('DD.MM.YYYY');
}

export function exhaustiveCheck(x: never) {}
