



















import { DataOwner } from '@model/Common';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';

const ANY_DATA_OWNER: DataOwner = {
  id: 'any',
  name: 'Any',
  isActive: true
};

interface SelectOption {
  id: string;
  name: string;
}

@Component({
  components: {}
})
export default class MBDataOwnerSelect extends Vue {
  @Model('change', { default: null }) value!: SelectOption | SelectOption[] | string | string[] | null;
  @Prop({ default: false, type: Boolean }) allowAny!: boolean;
  @Prop({ default: false, type: Boolean }) multiple!: boolean;
  @Prop({ default: false, type: Boolean }) allowInactive!: boolean;
  @Prop({ default: false, type: Boolean }) showDbUsers!: boolean;

  multiClass = {
    'multiselect-thead vertical-tags': this.multiple
  };

  multiProps = {
    'close-on-select': false,
    'clear-on-select': true,
    taggable: false,
    'show-labels': false,
    'option-height': 35
  };

  singleProps = {
    searchable: true,
    'allow-empty': false,
    'internal-search': true,
    'show-labels': false
  };

  private get allDataOwners(): DataOwner[] {
      var existingIds = this.$store.state.dataOwners.map(x => x.id);

      var usersNotDataOwner = this.$store.state.users.filter(function (item) {
          return existingIds.indexOf(item.id) === -1;
      });

    if (this.showDbUsers)
        return [...new Set([...this.$store.state.dataOwners, ...usersNotDataOwner])];
    else
        return this.$store.state.users;
  }

  get options(): SelectOption[] {
    const result: SelectOption[] = [];

    if (this.allowAny) {
      result.push(ANY_DATA_OWNER);
    }

    const allowedDataOwners = this.allowInactive
      ? this.allDataOwners
      : this.allDataOwners.filter(x => x.isActive);

    allowedDataOwners.forEach((a: DataOwner) => result.push(a));

    return result;
  }

  get controlValue(): SelectOption | SelectOption[] | null {
    if (this.multiple) {
      if (!Array.isArray(this.value) || this.value.length === 0) {
        return this.allowAny ? [ANY_DATA_OWNER] : [];
      }

      if (typeof this.value[0] === 'string') {
        return this.options.filter(x => (this.value as string[]).some(v => v == x.id));
      }

      if (this.isSelectOption(this.value[0])) {
        return this.value as SelectOption[];
      }
    } else {
      if (!this.value) {
        return this.allowAny ? ANY_DATA_OWNER : null;
      }

      if (typeof this.value === 'string') {
        return this.options.find(x => x.id === this.value) ?? null;
      }

      if (this.isSelectOption(this.value)) {
        return this.value;
      }
    }

    return null;
  }

  isSelectOption(object: unknown): object is SelectOption {
    return (
      'name' in (object as any) &&
      'id' in (object as any) &&
      typeof (object as any).name === 'string' &&
      typeof (object as any).id === 'string'
    );
  }

  updateSelected(option: SelectOption | SelectOption[]) {
    if (Array.isArray(option)) {
      const allDataOwners = option.find(x => x.id === ANY_DATA_OWNER.id);
      this.$emit('change', allDataOwners ? [] : option.map(x => x.id));
    } else {
      this.$emit('change', option.id === ANY_DATA_OWNER.id ? null : option.id);
    }
  }
}
