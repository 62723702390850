















import { toNumber } from "lodash-es";
import { Component, Vue, Model } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MBSortOrderInput extends Vue {
  @Model("change", { default: 1 }) value!: number | null;

  onChanged(value: string) {
    this.$emit("change", toNumber(value));
  }

  handleIntegerInput(e: any) {
    const keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
    }
  }
}
