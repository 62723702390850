export interface ValidationRuleType {
  validationRuleTypeId: ValidationRuleTypeId;
  name: string;
  description: string;
  contentTemplate: string;
}

export enum ValidationRuleTypeId {
  FloorAndCeiling = 'a5b4399a-4eba-4abb-a8aa-fbb35fc4dd84',
  RollingAverage = '5cfd1490-d905-41e9-9c99-8fa05c262fee',
  LastEntry = 'ff07d5f1-b3f7-44ee-a14a-e97efdabfc8a'
}

export interface ValidationRule {
  validationRuleId: string;
  name: string;
  parameterFirst: number | null;
  parameterSecond: number | null;
  validationRuleTypeId: string;
  type: ValidationRuleType;
  content: string;
}

export interface IFailedValidationResult {
  segmentId: number;
  segmentName: string;
  frequency: number;
  areaId: number;
  businessId: number;
  date: string;
  validationRuleMessage: string[];
}

export interface ValidationRuleDTO {
  id: string;
  content: string;
  description: string;
  type: string;
}

export interface UpdateValidationRule {
  validationRuleName: string;
  parameterFirst: number | null;
  parameterSecond: number | null;
}

export interface AddValidationRule {
  validationRuleName: string;
  parameterFirst: number | null;
  parameterSecond: number | null;
  validationRuleTypeId: string;
}

export interface GetRulesResultDto {
  top: ValidationRuleRequest[];
  bottom: ValidationRuleRequest[];
}

export interface ValidationRuleRequest {
  id: string;
  name: string;
  grouping: string;
  selected: boolean;
}
