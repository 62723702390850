


























































































































import {Component} from 'vue-property-decorator';
import {MbTableMixin} from '@mixins/MbTableMixin';
import CreateUpdateDeletePopUp from '../components/create-update-delete-pop-up.vue';
import {getContents, getValidationRuleTypes} from '@/api/validation-rules';
import {ApiError, toApiError} from '@model/ApiError';
import {ValidationRuleDTO} from '@model/ValidationRule';
import {transformToVueTableFormat} from '@model/tools';
import TablePagination from '@components/TablePagination.vue';
import {PaginationParametersDto, SortingParametersDto} from '@services/dtos/common';
import {SortParameter, toSortingParameters} from '@model/Table';
import {AxiosError} from 'axios';
import {debounce, isEmpty} from 'lodash-es';
import {getCurrentUser} from '@/api/users';
import {User} from "@model/User";
import AccessDenied from "@views/access-denied.vue";
import ContentLoading from "@views/content-loading.vue";

@Component({
  components: {
    ContentLoading,
    'access-denied': AccessDenied,
    CreateUpdateDeletePopUp,
    'table-pagination': TablePagination
  }
})
export default class ValidationRules extends MbTableMixin {
  selectedValidationRuleId: string | null = null;
  public currentUser: User = { isAdmin: false, name: '', isActive: false, userId: '', email: '' };
  pageLoading = false;
  modalKey = 0;

  defaultSortOrder: SortParameter[] = [
    {
      direction: 'asc',
      sortField: 'description',
      field: 'description'
    }
  ];

  pagination: PaginationParametersDto = {
    perPage: 10,
    page: 1
  };

  totalCount = 0;

  filter: ValidationRulesFilter = {
    content: {
      value: [],
      options: []
    },
    description: null,
    type: {
      value: [],
      options: []
    }
  };

  setFirstPageAndRefreshTableLazy = debounce(this.setFirstPageAndRefreshTable, 1000);

  setFirstPageAndRefreshTable() {
    this.pagination.page = 1;
    this.refreshTable();
  }

  public settings: any = {
    apiUrl: '/web/validationRules/manageValidationsFiltered',
    fields: [
      {
        name: 'content',
        title: 'Rule content',
        sortField: 'content',
        titleClass: 'sorting'
      },
      {
        name: 'description',
        title: 'Rule description',
        sortField: 'description',
        titleClass: 'sorting'
      },
      {
        name: 'type',
        title: 'Rule type',
        sortField: 'type',
        titleClass: 'sorting'
      },
      {name: 'actions-slot', title: 'Edit'}
    ]
  };

  async created(): Promise<void> {
    this.currentUser = await getCurrentUser();
    await this.onInit();
  }

  async onInit() {
    this.pageLoading = true;
    await this.getContentOptions();
    await this.getValidationRuleTypesOptions();
    this.pageLoading = false;
  }

  getContentOptions(): Promise<void> {
    return getContents()
        .then(response => {
          this.filter.content.options = response.map(content => {
            return {id: content, text: content};
          });
        })
        .catch((error: ApiError) => {
          switch (error.type) {
            case 'internal_server_error':
            case 'action_not_found':
            case 'incorrect_payload':
            default:
              this.showError('Internal Server Error');
              break;
          }
        });
  }

  getValidationRuleTypesOptions(): Promise<void> {
    return getValidationRuleTypes()
        .then(response => {
          this.filter.type.options = response.map(x => {
            return {id: x.validationRuleTypeId, text: x.name};
          });
        })
        .catch((error: ApiError) => {
          switch (error.type) {
            case 'internal_server_error':
            case 'action_not_found':
            case 'incorrect_payload':
            default:
              this.showError('Internal Server Error');
              break;
          }
        });
  }

  errorOccured(error: AxiosError) {
    if (process.env.NODE_ENV !== 'production') {
      console.dir(error);
    }
    const apiError = toApiError(error);
    switch (apiError.type) {
      case 'internal_server_error':
      case 'action_not_found':
      case 'incorrect_payload':
      default:
        this.showError('Internal Server Error');
        break;
    }
  }

  paginationChanged() {
    this.refreshTable();
  }

  editValidationRule(validationRuleId: string): void {
    this.selectedValidationRuleId = validationRuleId;
    this.openCreateUpdateDeleteValidationRuleModal();
  }

  createValidationRule(): void {
    this.openCreateUpdateDeleteValidationRuleModal();
  }

  onValidationRuleCreated(result: boolean): void {
    if (result) {
      this.refreshTable();
    }
    this.selectedValidationRuleId = null;
  }

  openCreateUpdateDeleteValidationRuleModal(): void {
    this.modalKey++;
    this.$nextTick(() => {
      this.$bvModal.show('create-validation-rule-pop-up');
    });
  }

  transformResponse(response: ValidationRulesManageValidationSearchResultDto) {
    this.totalCount = response.totalCount;
    return transformToVueTableFormat(response);
  }

  makeQueryParams(sortOrder: SortParameter[]): ValidationRulesManageValidationParametersDto {
    const contents = this.filter.content.value.map(x => x.id);
    const validationRuleTypeIds = this.filter.type.value.map(x => x.id);

    const filters: Partial<ValidationRulesManageValidationFilterDto> = {
      contents: isEmpty(contents) ? undefined : contents,
      validationRuleTypeIds: isEmpty(validationRuleTypeIds) ? undefined : validationRuleTypeIds,
      description: isEmpty(this.filter.description) ? undefined : this.filter.description
    };

    const query: ValidationRulesManageValidationParametersDto = {
      pagination: {
        page: this.pagination.page,
        perPage: this.pagination.perPage
      },
      filter: Object.values(filters).every(el => el === undefined) ? null : filters,
      sorting: sortOrder.length
          ? toSortingParameters(sortOrder[0])
          : toSortingParameters(this.defaultSortOrder[0])
    };

    return query;
  }

  private showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true
    });
  }
}

interface MultiselectOptions {
  id: string;
  text: string;
}

interface ValidationRulesFilter {
  content: {
    value: MultiselectOptions[];
    options: MultiselectOptions[];
  };
  type: {
    value: MultiselectOptions[];
    options: MultiselectOptions[];
  };
  description: string | null;
}

interface ValidationRulesManageValidationSearchResultDto {
  totalCount: number;
  page: number;
  perPage: number;
  entries: ValidationRuleDTO[];
}

interface ValidationRulesManageValidationFilterDto {
  contents: string[];
  description: string | null;
  validationRuleTypeIds: string[];
}

interface ValidationRulesManageValidationParametersDto {
  filter: Partial<ValidationRulesManageValidationFilterDto> | null;
  pagination: PaginationParametersDto;
  sorting: SortingParametersDto;
}
