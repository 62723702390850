













import { VuetablePaginationInfoMixin } from 'vuetable-2';
import Vue from 'vue';
import { Component } from "vue-property-decorator";

@Component({
  name: "vuetable-pagination-info-with-filter",
  mixins: [VuetablePaginationInfoMixin],
})
export default class PaginationInfoWithFilter extends Vue {}
