import { IdNamePair } from './Common';
import { ImportSource } from './ImportData';

export class Filters {
  public area: { id: number; name: string } | null;
  public business: { id: number; name: string } | null;
  public frequency: { id: number; name: string } | null;
  public isOverdueTimeSeriesOnly;

  constructor(
    area: { id: number; name: string } | null = null,
    business: { id: number; name: string } | null = null,
    frequency: { id: number; name: string } | null = null,
    isOverdueTimeSeriesOnly = false
  ) {
    this.area = area;
    this.business = business;
    this.frequency = frequency;
    this.isOverdueTimeSeriesOnly = isOverdueTimeSeriesOnly;
  }
}

export interface IFilterItem {
  id: number;
  name: string;
}

export const ANY_AREA: IdNamePair<number> = {
  id: -1,
  name: 'Any'
};

export const ANY_BUSINESS: IdNamePair<number> = {
  id: -1,
  name: 'Any'
};

export const ANY_DATA_OWNER: IdNamePair<number> = {
  id: -1,
  name: 'Any'
};

export const ANY_PERIOD: IdNamePair<number> = {
  id: -1,
  name: 'Any'
};

export const ANY_IMPORT_SOURCE: ImportSource = {
  key: '',
  name: 'Any'
};
