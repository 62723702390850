



















import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator';
@Component({
  components: {},
  inheritAttrs: false
})
export default class MMultiselect extends Vue {
  @ModelSync('value', 'input', { type: Object }) model!: object;

  @Prop({ default: '', type: String })
  multiSelectClass!: string;

  @Prop({ default: false, type: Boolean })
  showErrorMessage!: boolean;

  @Prop({ default: true, type: Boolean })
  state!: boolean;

  @Prop({ default: () => [], type: Array })
  errorMessages!: string[];
}
