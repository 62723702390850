// defined interfaces

import { TimeSerieGlobalFilter } from '@model/TimeSerie';

interface IFiltersContextState {
  filters: TimeSerieGlobalFilter | null;
}

// state
const state: IFiltersContextState = {
  filters: null
};

// mutations
function setFilters(state: IFiltersContextState, filters: TimeSerieGlobalFilter) {
  state.filters = filters;
}

function setOverdueTimeSeries(state: IFiltersContextState, isOverdueTimeSeriesOnly: true | null) {
  if (state.filters) {
    state.filters.isOverdueTimeSeriesOnly = isOverdueTimeSeriesOnly;
  } else {
    state.filters = {
      areaId: null,
      businessId: null,
      frequencyId: null,
      isOverdueTimeSeriesOnly: isOverdueTimeSeriesOnly
    };
  }
}

function dropFilters(state: IFiltersContextState) {
  state.filters = null;
}

const mutations = {
  setFilters,
  setOverdueTimeSeries,
  dropFilters
};

const actions = {};
const getters = {};

export default {
  state,
  getters,
  mutations,
  actions
};
