import AccessDenied from '@views/AccessDenied.vue';
import Users from '@views/Users.vue';
import AssignRulesTimeSeries from '@views/AssignRulesTimeSeries.vue';
import Home from '@views/Home.vue';
import TimeSeriesImport from '@views/time-series/TimeSeriesImport.vue';
import TimeSeriesManual from '@views/time-series/TimeSeriesManual.vue';
import ValidationRules from '@views/ValidationRules.vue';
import Vue from 'vue';
import Router from 'vue-router';
import {getCurrentUser} from '@/api';
import NotLoggedIn from "@views/NotLoggedIn.vue";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '',
    routes: [
        {
            path: '/', 
            name: '/', 
            component: Home
        },
        {
            path: '/time-series-import',
            name: 'timeSeriesImport',
            component: TimeSeriesImport
        },
        {
            path: '/time-series-manual',
            name: 'timeSeriesManual',
            component: TimeSeriesManual
        },
        {path: '/users', name: 'users', component: Users},
        {
            path: '/validationRules',
            name: 'validationRules',
            component: ValidationRules
        },
        {
            path: '/assignRulesTimeSeries',
            name: 'assignRulesTimeSeries',
            component: AssignRulesTimeSeries
        }
    ]
});


export default router;
