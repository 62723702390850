






































































































import { getAreaName, getBusinessName, getPeriodName, IdNamePair } from '@model/Common';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { setUserSegments } from '@/api/users';
import { ApiError } from '@model/ApiError';
import { getTimeSeriesActive } from '@/api/time-series';
import { getAllAreas, getAllBusinesses, getAllPeriods } from '@/api';
import { eventBus } from '../../main';
import { TimeSeriesDataProviders } from '@model/User';
import { BCard, BFormInput } from 'bootstrap-vue';



@Component({
  components: {}
})
export default class AddNewDataProvider extends Vue {
  @Prop({ default: () => [], required: true, type: Array }) users!: User[];
  @Prop({ default: () => {}, required: true }) refreshUsers!: () => void;

  formLoading = false;
  areaId: number | null = null;
  businessId: number | null = null;
  frequencyId: number | null = null;
  segment: IdNamePair<number> | null = null;
  segments: IdNamePair<number>[] = [];
  segmentLoading = false;
  segmentDisabled = true;
  dataProviders: User[] | null = null;

  newTimeSerieDataProviders: TimeSeriesDataProviders[] = [];

  async created(): Promise<void> {
    await this.restoreCommonData();
    this.$watch(() => this.areaId, this.areaChanged);
    this.$watch(() => this.businessId, this.businessChanged);
    this.$watch(() => this.frequencyId, this.frequencyChanged);
    eventBus.$on('onSaveClick', this.onSaveClicked);
  }

  beforeDestroy(): void {
    eventBus.$off('onSaveClick', this.onSaveClicked);
  }

  async OnAddDataProvider(): Promise<void> {
    if (!this.areaId || !this.businessId || !this.frequencyId || !this.segment || !this.dataProviders) {
      return;
    }

    this.dataProviders.forEach(dataProvider => {
      if (
        this.newTimeSerieDataProviders.some(
          x =>
            x.segmentId === this.segment!.id &&
            x.areaId === this.areaId! &&
            x.businessId === this.businessId! &&
            x.frequencyId === this.frequencyId! &&
            x.dataProviderId === dataProvider.userId
        )
      ) {
        return;
      }

      this.newTimeSerieDataProviders.push({
        segmentName: this.segment!.name,
        segmentId: this.segment!.id,
        areaId: this.areaId!,
        businessId: this.businessId!,
        frequencyId: this.frequencyId!,
        dataProviderName: dataProvider!.name,
        dataProviderId: dataProvider!.userId
      });
    });
  }

  private async uploadDataProviders(): Promise<void> {
    if (!this.newTimeSerieDataProviders || this.newTimeSerieDataProviders.length === 0) {
      return;
    }
  
    this.formLoading = true;
    try {
      await setUserSegments(this.newTimeSerieDataProviders);
      this.$toast.success('The data were successfully saved');
    }
    catch (e) {
      const error = e as ApiError;
      switch (error.type) {
        case 'bad_request':
          this.showError(error.message ?? 'Bad Request');
          break;
        case 'not_found':
          this.showError(error.message ?? 'Not Found');
          break;
        case 'internal_server_error':
        case 'action_not_found':
        case 'incorrect_payload':
        default:
          this.showError('Internal Server Error');
          break;
      }
    }
    finally {
      this.formLoading = false;
    }
    
    this.newTimeSerieDataProviders = [];
  }

  async onSaveClicked(): Promise<void> {
    await this.uploadDataProviders();
    this.refreshUsers();
  }

  private restoreCommonData() {
    const restoreAreas = getAllAreas().then(data => this.$store.commit('areasReceived', data));

    const restoreBusinesses = getAllBusinesses().then(data => this.$store.commit('businessesReceived', data));

    const restorePeriods = getAllPeriods().then(data => this.$store.commit('periodsReceived', data));

    return Promise.all([restoreAreas, restoreBusinesses, restorePeriods]);
  }

  async loadSegments(): Promise<void> {
    if (!this.areaId || !this.businessId || !this.frequencyId) {
      return;
    }

    this.segmentLoading = true;
    this.segmentDisabled = true;

    return getTimeSeriesActive(this.areaId, this.businessId, this.frequencyId)
      .then(response => {
        this.segments = response;
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => {
        this.segmentDisabled = false;
        this.segmentLoading = false;
      });
  }

  async handleSegmentSelect(): Promise<void> {
    this.segment = null;
    this.segments = [];

    await this.loadSegments();
  }

  async areaChanged(): Promise<void> {
    await this.handleSegmentSelect();
  }

  async businessChanged(): Promise<void> {
    await this.handleSegmentSelect();
  }

  async frequencyChanged(): Promise<void> {
    await this.handleSegmentSelect();
  }

  toAreaName(id: number) {
    return getAreaName(this.$store.state.areas, id);
  }

  toBusinessName(id: number) {
    return getBusinessName(this.$store.state.businesses, id);
  }

  toPeriodName(id: number) {
    return getPeriodName(this.$store.state.periods, id);
  }

  private showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true
    });
  }
}

interface User {
  userId: string;
  name: string;
  isAdmin: boolean;
}
