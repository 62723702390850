































































import Vue from 'vue';
import { Component } from "vue-property-decorator";
import { VuetablePaginationMixin } from 'vuetable-2';

@Component({
  name: "vuetable-pagination-bootstrap",
  mixins: [VuetablePaginationMixin]
})
export default class Pagination extends Vue {
  tablePagination: any;

  get numberOfPages() {

    const pagesCount = Math.ceil(
      this.tablePagination.filtered / this.tablePagination.per_page
    );

    return pagesCount <= 5 ? pagesCount : 5;
  }

  public showEllipsis(windowStart, notEnoughPages) {

    const lastPage = this.tablePagination.last_page;

    let lastPageInGroup = windowStart + this.numberOfPages - 1;
    lastPageInGroup = lastPageInGroup > lastPage ? lastPage : lastPageInGroup;

    return !notEnoughPages && lastPage != lastPageInGroup;
  }

  get totalPage() {
    if (!this.tablePagination) {
      return 0;
    }

    return Math.ceil(
      this.tablePagination.filtered / this.tablePagination.per_page
    );
  }

  get isOnFirstPage() {
    if (!this.tablePagination) {
      return true;
    }

    return this.tablePagination.current_page === 1;
  }

  get isOnLastPage() {
    if (!this.tablePagination) {
      return true;
    }

    const pagesCount = Math.ceil(
      this.tablePagination.filtered / this.tablePagination.per_page
    );
    return this.tablePagination.current_page === pagesCount;
  }

  get lastPageNumber() {
    return Math.ceil(
      this.tablePagination.filtered / this.tablePagination.per_page
    );
  }
}
