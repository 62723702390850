




















































import Vue from 'vue';
import { Component, Model, Prop } from "vue-property-decorator";
import { PaginationParameters } from '@/models/Common';
import { range } from 'lodash-es';


@Component({})
export default class TablePagination extends Vue {
  @Model("change", { default: () => ({ perPage: 10, page: 1 }), type: Object }) value!: PaginationParameters;

  @Prop({ default: () => [10, 25, 50, 100], type: Array }) pageSizeOptions!: Array<number>;
  @Prop({ default: 0, type: Number }) totalCount!: number;

  get pageSize() {
    return this.value.perPage;
  }

  get currentPage() {
    return this.value.page;
  }

  get pagesWindow() {
    // NOTE: final aim is to show always 7 pages refs
    // pages window (up to 5)
    // optional ellipsis
    // last page

    // NOTE: lodash range dfunction skips the last value in range

    const lastPage = this.numberOfPages;
    const current = this.currentPage;
    const showEllipsis = this.showEllipsis;

    let start = 1;
    let end = lastPage - 1;

    if (current === 1) {
      end = showEllipsis ? 5 : lastPage - 1;
    } else if (current === lastPage) {
      start = Math.max(lastPage - 6, 1);
    } else {
      start = Math.max(1, Math.min(current - 2, lastPage - 6));
      end = showEllipsis ? Math.max(5, current + 2) : lastPage - 1;
    }

    return range(start, end + 1);

  }

  // The same as last page
  get numberOfPages() {
    return this.totalCount > 0 ? Math.ceil(this.totalCount / this.pageSize) : 0;
  }

  get entriesFrom() {
    return (this.currentPage - 1) * this.pageSize + 1;
  }

  get entriesTo() {
    return this.currentPage * this.pageSize;
  }

  get showEllipsis() {
    return (this.numberOfPages - this.currentPage) > 4;
  }

  changePage(value: number | 'prev' | 'next') {
    if (value === 'prev') {
      this.value.page = Math.max(this.value.page - 1, 1);
    } else if (value === 'next') {
      this.value.page = Math.min(this.value.page + 1, this.numberOfPages);
    } else {
      this.value.page = value;
    }

    this.$emit("change", this.value);
  }



  onPageSizeChanged(pageSize: number) {
    if (this.pageSize === pageSize) { return; }

    this.value.page = 1;
    this.value.perPage = pageSize;
    this.$emit("change", this.value);
  }
}
