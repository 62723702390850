


























import { Component, Vue } from 'vue-property-decorator';
import { eventBus } from '../main';

import AddNewDataProvider from '@/views/users/add-new-data-provider.vue';
import AdministratorsSelection from '@/views/users/administrators-selection.vue';
import UserRightsTable from '@/views/users/user-rights-table.vue';
import AccessDenied from "@views/access-denied.vue";
import { getAllUsers, getCurrentUser } from '@/api';
import ContentLoading from "@views/content-loading.vue";

Component.registerHooks(['beforeRouteLeave']);
@Component({
  components: {
    ContentLoading,
    'access-denied': AccessDenied,
    'add-new-data-provider': AddNewDataProvider,
    'administrators-selection': AdministratorsSelection,
    'user-rights-table': UserRightsTable
  }
})
export default class Users extends Vue {
  pageLoading = false;
  isAdministratorsSelectionDirty = false;

  users: User[]|null = null;

  currentUser: User|null = null;
  get currentUserIsAdmin() {
    return this.currentUser ? this.currentUser.isAdmin : false;
  }
  get currentUserId() {
    return this.currentUser ? this.currentUser.userId : "";
  }
  
  async created(): Promise<void> {
    this.pageLoading = true;
    this.currentUser = await getCurrentUser();
    await this.refreshUsers();
    
    this.pageLoading = false;
  }

  public beforeRouteLeave(to, from, next): void {
    // User is moving to another page within the app. If there are unsaved changes, prompt for confirmation
    if (this.isAdministratorsSelectionDirty) {
      if (window.confirm('You have unsaved changes. Do you wish to leave this page?')) {
        next();
      } else {
        next(false);
      }
    } else next();
  }

  async refreshUsers() {
    this.users = await getAllUsers();
  }
}

interface User {
  userId: string;
  name: string;
  isAdmin: boolean;
}
