import { AxiosResponse } from 'axios';
import { User} from '@model/User';
import { ImportSource } from './ImportData';

export interface Area {
  id: number;
  name: string;
  sortOrder: number;
  unit: number;
}

export interface Business {
  id: number;
  name: string;
  isActive: boolean;
}

export interface Frequency {
  id: number;
  name: string;
}

export interface DataOwner {
  id: string;
  name: string;
  isActive: boolean;
}

export interface PaginationParameters {
  page: number;
  perPage: number;
}

export interface SortingParameters {
  sortBy: string;
  sortOrder: 'asc' | 'desc';
}

export interface Size {
  id: number;
  name: string;
}

export function getAreaName(all: Area[], areaId: number) {
  return all.find(x => x.id === areaId)?.name ?? 'Unknown';
}

export function getBusinessName(all: Business[], businessId: number) {
  return all.find(x => x.id === businessId)?.name ?? 'Unknown';
}

export function getPeriodName(all: Frequency[], periodId: number) {
  return all.find(x => x.id === periodId)?.name ?? 'Unknown';
}

export function getDataOwnerName(all: DataOwner[], userId: string) {
  return all.find(x => x.id === userId)?.name ?? 'Unknown';
}

export function getUserName(all: User[], id: string) {
  return all.find(x => x.userId === id)?.name ?? 'Unknown';
}

export function getSizeName(all: Size[], sizeId: number) {
  return all.find(x => x.id === sizeId)?.name ?? '';
}

export function getImportSourceName(all: ImportSource[], key: string) {
  return all.find(x => x.key === key)?.name ?? 'Unknown';
}

// LEGACY

export interface Envelope {
  result: string;
  message: string;
  timeGenerated: Date;
  validationErrors: IModelError[];
  errorMessage: string;
}

export interface IEnvelope<T> {
  result: T;
  message: string;
  timeGenerated: Date;
  validationErrors: IModelError[];
  errorMessage: string;
}

export interface IModelError {
  key: string;
  errorMessages: string[];
}

export interface IResponseModel<T> {
  data: T[];
  links: { pagination: IPagination };
}

export interface IPagination {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  next_page_url: string;
  prev_page_url: string;
  from: number;
  to: number;
  filtered: number;
}

export enum ColumnType {
  String = 0,
  Number = 1,
  Boolean = 2,
  Date = 3,
  Guid = 4
}

export enum SearchType {
  StartsWith = 0,
  Contains = 1,
  EndsWith = 2,
  Equals = 3,
  MultipleRange = 4
}

export interface ResponseModalComponent extends Vue {
  show<T>(response: AxiosResponse<IEnvelope<T>>);
  hide(): void;
}

export interface IRouteLinkParam {
  to: string;
  text: string;
}

export interface SuccessResponseResult<T> {
  type: 'success';
  data: T;
}

export interface FailedResponseResult<T> {
  type: 'failed';
  statusCode: number;
  errors: string[];
}

export type ResponseResult<T> = SuccessResponseResult<T> | FailedResponseResult<T>;

export interface IdNamePair<T> {
  id: T;
  name: string;
}
