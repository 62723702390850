export interface VueTableResponseModel<T> {
  data: T[];
  links: {
    pagination: {
      total: number;
      per_page: number;
      current_page: number;
      last_page: number;
      next_page_url?: string;
      prev_page_url?: string;
      from: number;
      to: number;

      // TODO: verify whether we need this field
      filtered: number;
    };
  };
}

export function transformToVueTableFormat<T>(data: {
  page: number;
  perPage: number;
  totalCount: number;
  entries: T[];
}): VueTableResponseModel<T> {
  return {
    data: data.entries,
    links: {
      pagination: {
        total: data.totalCount,
        per_page: data.perPage,
        current_page: data.page,
        last_page: Math.ceil(data.totalCount / data.perPage),
        from: (data.page - 1) * data.perPage + 1,
        to: data.page * data.perPage,
        filtered: data.totalCount
      }
    }
  };
}
