import { IdNamePair } from '@model/Common';
import { CreateNewTimeSeriesItemDto, SegmentPricePair, TimeSerieDto } from '@model/TimeSerie';
import { SegmentChangeDto } from '@services/dtos/segment-updates';
import {
  CreateNewTimeImportedTimeSeriesItemDto,
  ImportedTimeSeriesEntryDto,
  ManualTimeSeriesEntryDto,
  UpdateImportedTimeSeriesItemDto
} from '@services/dtos/time-series';
import axios from 'axios';

export function getTimeSeriesWithRatePrice(): Promise<TimeSerieDto[]> {
  return axios.get<TimeSerieDto[]>('/web/rates-and-prices').then(r => r.data);
}

export function deleteRatePrices(deletedItems: SegmentPricePair[]) {
  return axios.post('/web/time-series/DeleteRatePrices', deletedItems);
}
export function addRatePrice(itemsToAdd: SegmentPricePair[]) {
  return axios.post('/web/time-series/AddRatePrice', itemsToAdd);
}

export function validateModel(itemsToValidate: SegmentPricePair[]) {
  return axios.post('/web/time-series/segments/validateModel', itemsToValidate);
}

export function validateTimeSeries(itemsToValidate: SegmentPricePair[]) {
  return axios.post('/web/time-series/segments/validate', itemsToValidate);
}

export function getTimeSeriesEmptyExternalSource(
  areaId: number,
  businessId: number,
  frequencyId: number
): Promise<IdNamePair<number>[]> {
  return axios
    .get<IdNamePair<number>[]>(
      `/web/time-series/segments/areas/${areaId}/businesses/${businessId}/frequencies/${frequencyId}/empty-external-source`
    )
    .then(r => r.data);
}

export function getTimeSeriesActive(
  areaId: number,
  businessId: number,
  frequencyId: number
): Promise<IdNamePair<number>[]> {
  return axios
    .get<IdNamePair<number>[]>(
      `/web/time-series/segments/areas/${areaId}/businesses/${businessId}/frequencies/${frequencyId}/active`
    )
    .then(r => r.data);
}

export function addNewManualTimeSeries(
  payload: CreateNewTimeSeriesItemDto
): Promise<ManualTimeSeriesEntryDto> {
  return axios.post<ManualTimeSeriesEntryDto>('web/time-series/manual/add', payload).then(r => r.data);
}

export function updateManualTimeSeries(changes: SegmentChangeDto[]) {
  return axios.put('web/time-series/manual/update', changes);
}
export function createNewImportedTimeSeriesItem(
  data: CreateNewTimeImportedTimeSeriesItemDto
): Promise<ImportedTimeSeriesEntryDto> {
  return axios.post<ImportedTimeSeriesEntryDto>('/web/time-series/imported', data).then(r => r.data);
}

export function saveImportedTimeSeriesUpdates(data: UpdateImportedTimeSeriesItemDto[]) {
  return axios.put('/web/time-series/imported', data);
}
