export function getUnitName(unit: number): string {
  switch (unit) {
    case 1:
      return 'USD/Day';
    case 2:
      return 'USD mill.';
    case 3:
      return 'USD/Ldt';
  }

  return 'Unknown';
}
