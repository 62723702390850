























import { updateAdmins } from '@/api';
import { eventBus } from '@/main';
import { ApiError } from '@model/ApiError';
import { BCard, BCardText } from 'bootstrap-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
})
export default class AdministratorsSelection extends Vue {
  @Prop({ default: () => {}, required: true }) refreshUsers!: () => void;
  @Prop({ default: () => [], required: true, type: Array }) users!: User[];
  @Prop({ default: () => "", required: true}) currentUserId;
  @Prop({ default: () => {}, required: true }) adminsUpdated!: () => Promise<void>;
  adminsRemove: string[] = [];
  
  adminUsersModel: User[] = [];
  isDirty = false;

  get allUsers(): User[] {
    return this.users ? this.users : [];
  }

  created(): void {
    eventBus.$on('onSaveClick', this.onSaveClicked);
    this.adminUsersModel = this.allUsers.filter(u => u.isAdmin);
  }
  beforeDestroy(): void {
    eventBus.$off('onSaveClick', this.onSaveClicked);
  }
  onRemove(user: User): void {
    if (user.userId === this.currentUserId) {
      alert("You can't remove yourself from admin role");
      const admins = this.allUsers.filter(u => u.isAdmin);
      const indexOfUser = admins.findIndex(u => u.userId === user.userId);
      this.adminUsersModel.splice(indexOfUser, 0, user);
      return;
    }
    this.adminsRemove.push(user.userId);
    this.isDirty = this.adminsRemove.length > 0;
  }
  onInput(selection: User[]) {
    this.isDirty = true;
  }

  async onSaveClicked(): Promise<void> {
    if (this.isDirty) {
      let toUnsetUserIds = this.adminsRemove;

      let toSetUserIds = this.adminUsersModel
        .filter(u => !this.adminsRemove.includes(u.userId))
        .map(u => u.userId);
      this.$store.commit('startGlobalSaveLoading');

      try {
        try {
           await updateAdmins(toUnsetUserIds, toSetUserIds);
           this.allUsers.forEach(u => {
            if(this.adminsRemove.includes(u.userId)) {
              u.isAdmin = false;
            }
           });
           this.adminsRemove.length = 0;
           this.adminUsersModel.forEach(u => {
              u.isAdmin = true
            });
          
        } catch (e) {
          const error = e as ApiError;
          switch (error.type) {
            case 'bad_request':
              this.$toast.error(error.message ?? "Something went wrong! Your modifications aren't saved!");
              break;
            case 'internal_server_error':
            case 'action_not_found':
            case 'incorrect_payload':
            default:
              this.showError("Something went wrong! Your modifications aren't saved!");
              break;
          }
        }
      } finally {
        
        if(this.adminsUpdated) {
          await this.adminsUpdated();
        }
        // this.$emit('adminsUpdated');
        this.$store.commit('endGlobalSaveLoading');
        this.$toast.success('The data were successfully saved');
      }
    }
  }

  private showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true
    });
  }
}

interface User {
  userId: string;
  name: string;
  isAdmin: boolean;
}
