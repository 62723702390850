import { IdNamePair } from '@model/Common';
import {
  AddValidationRule,
  GetRulesResultDto,
  UpdateValidationRule,
  ValidationRule,
  ValidationRuleType
} from '@model/ValidationRule';
import axios from 'axios';

export function getValidationRuleTypes() {
  return axios.get<ValidationRuleType[]>('/web/validationRules/validationRuleTypes').then(r => r.data);
}

export function getContents() {
  return axios.get<string[]>('/web/validationRules/contents').then(r => r.data);
}

export function getValidationRule(idValidationRule: string) {
  return axios.get<ValidationRule>(`/web/validationRules/${idValidationRule}`).then(r => r.data);
}

export function updateValidationRule(idValidationRule: string, dto: UpdateValidationRule) {
  return axios.post(`/web/validationRules/${idValidationRule}/update`, dto);
}

export function deleteValidationRule(idValidationRule: string) {
  return axios.delete(`/web/validationRules/${idValidationRule}/delete`);
}

export function addValidationRule(dto: AddValidationRule) {
  return axios.post('/web/validationRules/add', dto);
}

export function anyAssociationsWithSegments(idValidationRule: string) {
  return axios
    .get<boolean>(
      `web/validationRules/time-series/validationRules/${idValidationRule}/anyAssociationsWithSegments`
    )
    .then(x => x.data);
}

export function getSegmentsContents(): Promise<IdNamePair<string>[]> {
  return axios.get<IdNamePair<string>[]>('/web/validationRules/time-series/contents').then(r => r.data);
}

export function getSegments(): Promise<IdNamePair<number>[]> {
  return axios.get<IdNamePair<number>[]>('/web/validationRules/time-series/segments').then(r => r.data);
}

export function getValidationRulesBySegmentId(segmentId: number): Promise<GetRulesResultDto> {
  return axios
    .get<GetRulesResultDto>(`/web/validationRules/segments/${segmentId}/getRules`)
    .then(r => r.data);
}

export function associateValidationRules(segmentId: number, validationRuleIds: string[]) {
  return axios.post(
    `web/validationRules/time-series/segments/${segmentId}/validationRules/associate`,
    validationRuleIds
  );
}

export function removeAssociation(segmentId: number, validationRuleId: string) {
  return axios.post(
    `/web/validationRules/time-series/segments/${segmentId}/validationRules/${validationRuleId}/removeAssociation`
  );
}
