import { Area, Business, DataOwner, Frequency, Size } from '@model/Common';
import { User } from '@model/User';

export interface PaginationParametersDto {
  page: number;
  perPage: number;
}

export interface SortingParametersDto {
  sortBy: string;
  sortOrder: "asc" | "desc"
}

export interface AreaDto {
  id: number;
  name: string;
  sortOrder: number;
  unit: number;
}

export interface BusinessDto {
  id: number;
  name: string;
  isActive: boolean;
}

export interface FrequencyDto {
  id: number;
  name: string;
}

export interface DataOwnerDto {
  id: string;
  name: string;
  isActive: boolean;
}

export interface UserDto {
  userId: string;
  name: string;
  email: string;
  isActive: boolean;
  isAdmin: boolean;
}
export interface SizeDto {
  id: number;
  name: string;
}

export function toArea(dto: AreaDto): Area {
  return {
    ...dto
  };
}

export function toBusiness(dto: BusinessDto): Business {
  return {
    ...dto
  };
}

export function toFrequency(dto: FrequencyDto): Frequency {
  return {
    ...dto
  };
}

export function toDataOwner(dto: DataOwnerDto): DataOwner {
  return {
    id: dto.id,
    name: dto.name,
    isActive: dto.isActive
  };
}

export function toDataOwnerFromUser(dto: UserDto): DataOwner {
  return {
    id: dto.userId,
    name: dto.name,
    isActive: dto.isActive
  };
}

export function toSize(dto: SizeDto): Size {
  return {
    id: dto.id,
    name: dto.name
  };
}
