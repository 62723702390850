import { AxiosError } from 'axios';

export type ApiError =
  | IncorrectPayload
  | ActionNotFound
  | ValidationError
  | NotFound
  | BadRequest
  | UnexpectedError
  | Conflict;

export function toApiError(error: AxiosError): ApiError {
  if (!error.response) return { type: 'internal_server_error' };

  switch (error.response.status) {
    case 405:
      // Compare actual routes with one you are trying to access
      return { type: 'action_not_found' };
    case 400:
      return error.response.data.errors
        ? {
            type: 'validation_error',
            errors: error.response.data.errors
          }
        : {
            type: 'bad_request',
            message: error.response.data
          };
    case 404:
      return {
        type: 'not_found',
        message: error.response.data
      };
    case 409:
      return {
        type: 'conflict',
        response: error.response.data
      };
    case 415:
      // You are not able pass null values to backend
      return { type: 'incorrect_payload' };
    case 500:
      return { type: 'internal_server_error' };
    default:
      return { type: 'internal_server_error' };
  }
}

export interface IncorrectPayload {
  type: 'incorrect_payload';
}

export interface ActionNotFound {
  type: 'action_not_found';
}

export interface ValidationError {
  type: 'validation_error';
  errors: ValidationErrors;
}

export interface ValidationErrors {
  [key: string]: string[];
}

export interface NotFound {
  type: 'not_found';
  message: string | null;
}

export interface BadRequest {
  type: 'bad_request';
  message: string | null;
}

export interface UnexpectedError {
  type: 'internal_server_error';
}

export interface Conflict {
  type: 'conflict';
  response: any;
}
