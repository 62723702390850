import { Area, Business, DataOwner, Frequency, Size } from '@model/Common';
import { User } from '@model/User';
import {
  AreaDto,
  BusinessDto,
  DataOwnerDto,
  FrequencyDto,
  toArea,
  toBusiness,
  toDataOwner,
  toDataOwnerFromUser,
  toSize,
  toFrequency
} from '@services/dtos/common';

import axios from 'axios';

export function getAllAreas(): Promise<Area[]> {
  return axios.get<AreaDto[]>('/api/areas').then(response => response.data.map(toArea));
}

export function getAllBusinesses(): Promise<Business[]> {
  return axios.get<BusinessDto[]>('/api/businesses').then(response => response.data.map(toBusiness));
}

export function getAllPeriods(): Promise<Frequency[]> {
  return axios.get<FrequencyDto[]>('/api/periods').then(response => response.data.map(toFrequency));
}

export function getAllAdUsers(): Promise<DataOwner[]> {
    return axios.get<User[]>('/web/users/all').then(response => response.data.map(toDataOwnerFromUser));
 }

export function getAllDataOwners(): Promise<DataOwner[]> {
  return axios.get<DataOwnerDto[]>('/api/data-owners').then(response => response.data.map(toDataOwner));
}
export function getAllSizes(): Promise<Size[]> {
  return axios.get<Size[]>('/api/sizes').then(response => response.data.map(toSize));
}
