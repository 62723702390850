import { toApiError } from '@model/ApiError';
import axios, { AxiosError } from 'axios';
import router from './router';

export default function configureAxios() {
  axios.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      if (process.env.NODE_ENV !== 'production') {
        console.dir(error);
      }
      if (error.response && [401, 403].includes(error.response.status)) {
        router.push('/');
      }

      const apiError = toApiError(error);

      if (process.env.NODE_ENV !== 'production') {
        console.error(apiError);
      }

      return Promise.reject(apiError);
    }
  );
}
