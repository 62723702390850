import Vue from "vue";
import { Mixin } from "vue-mixin-decorator";
import {
    Validator,
    ErrorBag,
    FieldError,
} from 'vee-validate';

const validator = new Validator();
const errorBag = new ErrorBag();

@Mixin
export class ValidationMixin extends Vue {

    errorBag = errorBag;

    getErrorBag(): ErrorBag {
        return errorBag;
    }

    anyErrors(): boolean {
        return errorBag.any();
    }

    hasError(fieldName: string): boolean {
        return errorBag.has(fieldName);
    }

    getAllErrorMessages(): string[] {
        return errorBag.all();
    }

    getErrorMessages(fieldName: string): string[] {
        return errorBag.items.filter(i => i.field === fieldName).map(i => i.msg);
    }

    removeAllValidationErrors(): void {
        errorBag.clear();
    }

    addErrors(fieldError: FieldError[] | FieldError): void {
        errorBag.add(fieldError)
    }

    removeError(fieldName: string): void {
        errorBag.remove(fieldName);
    }

    removeById(id: string): void {
        errorBag.removeById(id);
    }

    // to add here rules like greater/less then etc. 
    // https://vee-validate.logaretm.com/v3/guide/rules.html#rules

    async required(
        filedName: string,
        value: string | number | null | undefined | [] | object,
        message?: string
    ): Promise<void> {
        let verifyResult = await validator.verify(value, `required`, {
            name: filedName,
        });

        if (!verifyResult.valid) {
            let errorMessage =
                message !== undefined
                    ? message.format([filedName])
                    : verifyResult.errors[0];

            this.addErrors({
                field: filedName,
                msg: errorMessage,
            })
        }
    }

    async between(
        filedName: string,
        value: number | null,
        params: { min: number; max: number },
        message?: string
    ): Promise<void> {
        let verifyResult = await validator.verify(
            value,
            `between:${params.min},${params.max}`,
            {
                name: filedName,
            }
        );

        if (!verifyResult.valid) {
            let errorMessage =
                message !== undefined
                    ? message.format([filedName, params.min, params.max])
                    : verifyResult.errors[0];

            this.addErrors({
                field: filedName,
                msg: errorMessage,
            })
        }
    }

    async moreThan(
        filedName: string,
        value: number | null,
        params: { min: number },
        message?: string
    ): Promise<void> {
        const defaultMessage = 'The {0} value must be more than {1}'
       
        if (value !== null && value <= params.min) {
            let errorMessage =
                message !== undefined
                    ? message.format([filedName, params.min])
                    : defaultMessage.format([filedName, params.min]);

            this.addErrors({
                field: filedName,
                msg: errorMessage,
            })
        }
    }

}//end Mixin