














































































































































import {getAreaName, getBusinessName, getImportSourceName, getPeriodName} from '@model/Common';
import {isEmpty, isNil, trim} from 'lodash-es';
import {Component, Vue} from 'vue-property-decorator';
import {ImportedTimeSeriesEntryDto, ImportResultDto} from '@services/dtos/time-series';
import {ApiError} from '@model/ApiError';
import {createNewImportedTimeSeriesItem, testImport} from '@/api';

@Component({
  components: {}
})
export default class NewImportedTimeSeries extends Vue {
  isAddingNewSeriesItem = false;
  waitingForImportTestResult = false;
  importSourceUnderTest: string | null = null;
  importCodeUnderTest: string | null = null;
  importTestResult: ImportResultDto | null = null;

  get importTestPassed() {
    return !isNil(this.importTestResult) && this.importTestResult.result === 'Success';
  }

  get importTestFailed() {
    return !isNil(this.importTestResult) && this.importTestResult.result === 'Failed';
  }

  areaId: number | null = null;
  businessId: number | null = null;
  frequencyId: number | null = null;
  segmentName: string | null = null;
  importSourceKey: string | null = null;
  importCode: string | null = null;

  validationErrors: string[] = [];

  newTimeSeries: ImportedTimeSeriesEntryDto[] = [];

  get hasUnsavedChanges() {
    return (
      this.isAddingNewSeriesItem ||
      !isNil(this.areaId) ||
      !isNil(this.businessId) ||
      !isNil(this.frequencyId) ||
      !isEmpty(trim(this.segmentName ?? ''))
    );
  }

  toAreaName(id: number) {
    return getAreaName(this.$store.state.areas, id);
  }

  toBusinessName(id: number) {
    return getBusinessName(this.$store.state.businesses, id);
  }

  toPeriodName(id: number) {
    return getPeriodName(this.$store.state.periods, id);
  }

  toImportSourceName(key: string) {
    return getImportSourceName(this.$store.state.importSources, key);
  }

  created() {
    this.$watch(
      () => this.importSourceKey,
      () => this.resetImportTestResult()
    );
    this.$watch(
      () => this.importCode,
      () => this.resetImportTestResult()
    );
  }

  async onNewTSTestImportClick() {
    const canRunTest =
      !this.waitingForImportTestResult && !isEmpty(this.importSourceKey) && !isEmpty(this.importCode);

    if (!canRunTest) {
      return;
    }

    const importSource = this.importSourceKey!;
    const importCode = this.importCode!;

    this.waitingForImportTestResult = true;
    this.importSourceUnderTest = this.toImportSourceName(importSource);
    this.importCodeUnderTest = importCode;

    this.$nextTick(() => this.$bvModal.show('new-ts-import-test-modal'));

    this.importTestResult = await testImport(importSource, importCode);
    this.waitingForImportTestResult = false;
    if(this.importTestResult.result === 'Success') {
      this.$toast.success('Test succeeded');  
    }
    else {
      this.$toast.error(this.importTestResult.failReason);
    }
    
    
  }

  resetImportTestResult() {
    this.importTestResult = null;
  }

  onAddNewTimeSeriesItemClick() {
    if (this.isAddingNewSeriesItem) {
      return;
    }

    const validationResult = this.validateNewTimeSeriesItemData();
    if (!validationResult.isValid) {
      this.validationErrors = [validationResult.error!];
      this.showValidationFailuresModal();
      return;
    }

    this.validationErrors = [];

    const area = this.areaId!;
    const business = this.businessId!;
    const frequency = this.frequencyId!;
    const importSource = this.importSourceKey!;
    const importCode = this.importCode!;

    this.isAddingNewSeriesItem = true;

    createNewImportedTimeSeriesItem({
      areaId: area,
      businessId: business,
      name: this.segmentName,
      frequency: frequency,
      importSource: importSource,
      importCode: importCode
    })
      .then(result => {
        this.newTimeSeries.push(result);
        this.resetInsertedTimeSerieValues();
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'validation_error':
            let allValidationErrorMessages: string[] = [];
            for (const key in error.errors) {
              allValidationErrorMessages.push(error.errors[key][0]);
            }
            this.showError(allValidationErrorMessages.join('\n'), true);
            break;
          case 'bad_request':
            this.showError(error.message ?? 'Bad request');
            break;
          case 'conflict':
            const errorMessage = error.response as string;
            this.showError(errorMessage);
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => (this.isAddingNewSeriesItem = false));
  }

  private showValidationFailuresModal() {
    this.$bvModal.show('new-ts-validation-failure-modal');
  }

  private resetInsertedTimeSerieValues() {
    this.areaId = null;
    this.businessId = null;
    this.frequencyId = null;
    this.segmentName = null;
    this.importSourceKey = null;
    this.importCode = null;
    this.importTestResult = null;
  }

  private validateNewTimeSeriesItemData(): {
    isValid: boolean;
    error?: string;
  } {
    const timeSeriesFieldsAreSet =
      !isNil(this.areaId) &&
      !isNil(this.businessId) &&
      !isNil(this.frequencyId) &&
      !isNil(this.segmentName) &&
      !isNil(this.importSourceKey) &&
      !isEmpty(this.importCode);

    if (!timeSeriesFieldsAreSet) {
      return {
        isValid: false,
        error: 'You need to fill out all the fields and test the time series before saving.'
      };
    }

    if (!this.importTestPassed) {
      return {
        isValid: false,
        error: 'You need to test the time series with successful server connection for saving the changes.'
      };
    }

    return { isValid: true };
  }

  private showError(message: string, newLine: boolean = false): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Error',
      headerBgVariant: 'card-header-label text-danger',
      size: newLine ? 'md' : 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Ok',
      hideHeaderClose: true,
      centered: true,
      bodyClass: newLine ? 'body-new-line' : ''
    });
  }
}
