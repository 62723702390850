













import { Component, Vue, Model } from "vue-property-decorator";


@Component({
  components: {},
})
export default class TimeSeriesImportCodeInput extends Vue {

  // TODO: fix. Specify types
  @Model('change', { default: null }) selectedImportCode: any | null;

  // TODO: fix. Specify types
  updateSelected(value: any) {    
    this.$emit('change', value);
  }  
}

