




































































































import { Component, Vue } from 'vue-property-decorator';
import Loader from '@/components/Loader.vue';
import { eventBus } from '../main';
import dayjs from 'dayjs';
import {
  AreaType,
  IGroupedTimeSeries,
  IGroupedTimeSerieValue,
  SegmentPricePair,
  Size,
  TimeSerieDto,
  TimeSerieGlobalFilter
} from '@model/TimeSerie';
import { User } from '@model/User';
import { formatForUnit, formatToFixedPrecision } from '@/helpers';
import { VNode } from 'vue/types/umd';
import { IFailedValidationResult } from '@model/ValidationRule';
import { isEmpty, toNumber } from 'lodash-es';
import {
  addRatePrice,
  deleteRatePrices,
  getTimeSeriesWithRatePrice,
  validateModel,
  validateTimeSeries
} from '@/api/time-series';
import { ApiError, ValidationErrors } from '@model/ApiError';
import { getCurrentUser } from '@/api/users';

const updateRatePrice = 'updateRatePrice';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  components: {
    'mbv-loader': Loader,
  }
})
export default class Home extends Vue {
  sizeFilters: SizeFilters = {};
  areaTypeFilters: AreaTypeFilters = {};

  private initialTimeSeries: IGroupedTimeSeries[] = [];
  private timeSeries: IGroupedTimeSeries[] = [];
  private filteredData: IGroupedTimeSeries[] = [];
  private copyPasted = Array<string>();
  public currentUser: User = { isAdmin: false, name: '', isActive: false, userId: '', email: '' };
  public isLoading: boolean = true;
  public loadingText = "Loading...";
  public nothingToShow: boolean = false;
  public changes: any[] = [];
  public deletedItems: SegmentPricePair[] = [];
  public errors: Map<string, string> = new Map();

  tabPressed(e, timeSerieIndex, timeSerieValuesIndex, ratePriceIndex) {
    if (e.shiftKey) {
      this.getPreviousElement(timeSerieIndex, timeSerieValuesIndex, ratePriceIndex).focus();
    } else {
      this.getNextElement(timeSerieIndex, timeSerieValuesIndex, ratePriceIndex).focus();
    }
  }

  getPreviousElement(groupIndex, rowIndex, colIndex): HTMLElement {
    let step = 1;

    // go in the currend group -> on previous row -> in the current column
    let previousElementByRowIndex = document.getElementById(
      `price-input-${groupIndex}-${rowIndex - step}-${colIndex}`
    );

    // found, great! no, go to the next condition
    if (previousElementByRowIndex !== null) return previousElementByRowIndex;

    // pattern is to get previous group index but we don't know last row

    //firstly check if there any groupe before current

    let anyPreviousElementByGroupIndex = document.getElementById(
      `price-input-${groupIndex - step}-0-${colIndex}`
    );

    if (anyPreviousElementByGroupIndex !== null) {
      // we need to get what is the last row, simply by adding the row by 1
      let row = 0;
      const group = groupIndex - step;
      while (true) {
        // we don't care about column for now
        let lastRow = document.getElementById(`price-input-${group}-${row + 1}-0`);
        if (lastRow !== null) {
          row += 1;
        } else {
          break;
        }
      }
      // now we know the row so go there!
      let previousElementByGroupIndex = document.getElementById(
        `price-input-${groupIndex - step}-${row}-${colIndex}`
      );
      return previousElementByGroupIndex!;
    }

    // if there no previous group have 2 ways
    // 1 there no previous column we need to get last group and row and go to the last column which is 5
    // 2 we have previous column we need to get last group and row and go there

    // firstly let's find last group
    let group = 0;
    while (true) {
      // we don't care about row and column for now
      let lastGroup = document.getElementById(`price-input-${group + 1}-0-0`);
      if (lastGroup !== null) {
        group += 1;
      } else {
        break;
      }
    }
    //now we have group and column, let's get row
    let row = 0;
    while (true) {
      // we don't care about column for now
      let lastRow = document.getElementById(`price-input-${group}-${row + 1}-0`);
      if (lastRow !== null) {
        row += 1;
      } else {
        break;
      }
    }

    if (colIndex === 0) {
      let previousElementByColIndex = document.getElementById(`price-input-${group}-${row}-5`);

      return previousElementByColIndex!;
    } else {
      let previousElementByColIndex = document.getElementById(`price-input-${group}-${row}-${colIndex - 1}`);

      return previousElementByColIndex!;
    }
  }

  getNextElement(groupIndex, rowIndex, colIndex): HTMLElement {
    let step = 1;
    // go in the currend group -> on next row -> in the current column
    let nextElementByRowIndex = document.getElementById(
      `price-input-${groupIndex}-${rowIndex + step}-${colIndex}`
    );
    // found, great! no, go to the next condition
    if (nextElementByRowIndex !== null) return nextElementByRowIndex;

    // if we can't go next(down) we go to the next group -> in the current column-> row always will be 0
    let nextElementByGroupIndex = document.getElementById(`price-input-${groupIndex + step}-0-${colIndex}`);

    // found, great! no, go to the next condition
    if (nextElementByGroupIndex !== null) return nextElementByGroupIndex;

    // if we are not on the last column we go to the next column, group and row always will be 0
    if (colIndex !== 5) {
      let nextElementByColIndex = document.getElementById(`price-input-0-0-${colIndex + step}`);

      // found, great! no way we didn't find
      if (nextElementByColIndex !== null) return nextElementByColIndex;
    }

    // if we were on last column there no where to go, we go back at 0-0-0
    return document.getElementById('price-input-0-0-0')!;
  }

  get contextFilters(): TimeSerieGlobalFilter | null {
    return this.$store.state.filtersContext.filters;
  }

  async created(): Promise<void> {
    this.currentUser = await getCurrentUser();
    this.$store.commit('dropFilters');
    window.addEventListener('beforeunload', this.onBeforeUnload);
    eventBus.$on('onSaveClick', this.onSaveClicked);
    eventBus.$on('appliedFilters', this.onFiltersApplied);

    await this.loadData();
  }

  private async loadData(): Promise<void> {
    this.errors = new Map();
    this.isLoading = true;
    this.deletedItems = [];
    this.changes = [];



    await getTimeSeriesWithRatePrice()
      .then(response => {
        if (response.length > 0) {
          this.timeSeries = this.toTimeSeriesGroups(response);
          this.initialTimeSeries = JSON.parse(JSON.stringify(this.timeSeries));
          this.updateFilteredData();
          this.resetSizeAndAreaTypeFilters();
        }
        else {
          this.nothingToShow = true;
        }
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.showError('Internal Server Error');
            break;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  get localAndGlobalFilteredData() {
    const localAndGlobalFilteredData: IGroupedTimeSeries[] = [];

    for (let index = 0; index < this.filteredData.length; index++) {
      //global filtered
      const timeSerie = this.filteredData[index];
      //local Filtered
      const filteredValues = timeSerie.values.filter(
        x =>
          this.filterForSizeFilter(x, timeSerie.key) && this.filterForAreaTypeFilter(x, timeSerie.key)
      );

      localAndGlobalFilteredData.push({
        key: timeSerie.key,
        values: filteredValues,
        visible: timeSerie.visible
      });
    }
    return localAndGlobalFilteredData;
  }

  filterForSizeFilter(x: IGroupedTimeSerieValue, key: string) {
    return this.sizeFilters[key].length > 0
      ? // if 'All' is selected ( by default id is 0 ) - show segments
      this.sizeFilters[key].some(s => s.sizeId === 0) ||
      this.sizeFilters[key].some(s => s.sizeId === x.timeSerie.size?.sizeId)
      : //show all segments if filter is not selected
      true;
  }

  filterForAreaTypeFilter(x: IGroupedTimeSerieValue, key: string) {
    return this.areaTypeFilters[key].length > 0
      ? // if 'All' is selected ( by default id is o ) show areaTypes
      this.areaTypeFilters[key].some(s => s.areaTypeId === 0) ||
      this.areaTypeFilters[key].some(s => s.areaTypeId === x.timeSerie.areaType?.areaTypeId)
      : //show all area types if filter is not selected
      true;
  }

  resetSizeAndAreaTypeFilters() {
    this.sizeFilters = {};
    this.areaTypeFilters = {};
    this.filteredData.forEach(fd => {
      this.$set(this.sizeFilters, fd.key, []);
      this.$set(this.areaTypeFilters, fd.key, []);
    });
  }

  getGroupName(key: string): string {
    const timeSerie = this.filteredData.find(x => x.key === key);
    return timeSerie ? `${key} (${timeSerie.values[0].unit})` : '';
  }

  isWeeklyFrequency(key: string): boolean {
    const timeSerie = this.filteredData.find(x => x.key === key);
    return timeSerie ? timeSerie.values[0].timeSerie.frequency == 'Weekly' : false;
  }

  getCopyPastedClass(key: string) {
    const timeSerie = this.filteredData.find(x => x.key === key);
    if (!timeSerie) {
      return '';
    }

    return this.copyPasted.indexOf(timeSerie.values[0].timeSerie.timeSeriesId.toString()) > -1 ? 'undo' : '';
  }

  hideShowTimeSerie(key: string) {
    const timeSerie = this.filteredData.find(x => x.key === key);
    if (timeSerie) {
      timeSerie.visible = !timeSerie.visible;
    }
  }

  getFirstRateAndPrices(key: string) {
    const timeSerie = this.filteredData.find(x => x.key === key);

    return timeSerie ? timeSerie.values[0].timeSerie.ratePrices.slice(0, 6) : [];
  }

  getFirstFrequency(key: string) {
    const timeSerie = this.filteredData.find(x => x.key === key);
    return timeSerie ? timeSerie.values[0].timeSerie.frequency : '';
  }

  onFiltersApplied(): void {
    this.updateFilteredData();
    this.resetSizeAndAreaTypeFilters();
  }

  onSaveClicked(showToast = true): void {
    if (showToast) {
      // The global save button is only here to remove focus from input field.
      // data is saved on blur event on input field
      return;
    }

    let itemsToValidate = this.createRatePriceModel();

    if (!itemsToValidate.length && !this.deletedItems.length) {
      return;
    }

    if (itemsToValidate.length) {
      this.validateChangedItems(showToast, itemsToValidate);
    } else {
      this.deletedItems = [];
      this.save(showToast);
    }
  }

  validateChangedItems(showToast: boolean, itemsToValidate: SegmentPricePair[]) {
    validateModel(itemsToValidate)
      .then(() => {
        validateTimeSeries(itemsToValidate)
          .then(() => {
            this.deletedItems = [];
            this.save(showToast);
          })
          .catch((error: ApiError) => {
            switch (error.type) {
              case 'conflict':
                const conflictDto = error.response as IFailedValidationResult[];
                this.showWarningPrompt(conflictDto).then(promptResponse => {
                  if (promptResponse) {
                    this.save(showToast);
                  }
                });

                break;
              case 'internal_server_error':
              case 'action_not_found':
              case 'incorrect_payload':
              default:
                this.changes = [];
                this.filteredData = this.timeSeries;
                location.href = '/Account/SignIn';
                break;
            }
          });
          
      })
      .catch((error: ApiError) => {
        switch (error.type) {
          case 'validation_error':
            this.$bvModal.msgBoxOk(this.getErrorFromResponse(error.errors), {
              id: 'error-modal',
              title: 'Error',
              hideHeaderClose: true,
              hideFooterOk: true,
              size: 'md',
              centered: true
            });

            this.$store.commit('endGlobalSaveLoading');
            break;
          case 'internal_server_error':
          case 'action_not_found':
          case 'incorrect_payload':
          default:
            this.changes = [];
            this.filteredData = this.timeSeries;
            location.href = '/Account/SignIn';
            break;
        }
      });
  }

  private save(showToast = true): void {
    this.loadingText = "Saving...";
    const rateAndPricesToAdd = this.createRatePriceModel();
    if (this.deletedItems.length || rateAndPricesToAdd.length) {
      this.$store.commit('startGlobalSaveLoading');
    }

    if (this.deletedItems.length) {
      deleteRatePrices(this.deletedItems)
        .then(async () => {
          await this.loadData();
          if(showToast) {
            this.$toast.success('The data were successfully deleted');
          }
        })
        .catch((error: ApiError) => {
          switch (error.type) {
            case 'not_found':
              this.showError(error.message ?? 'Not Found');
              break;
            case 'internal_server_error':
            case 'action_not_found':
            case 'incorrect_payload':
            default:
              this.showError('Internal Server Error');
              break;
          }
        })
        .finally(() => {
          this.$store.commit('endGlobalSaveLoading');
          this.loadingText = "Loading...";
        });
    }

    if (rateAndPricesToAdd.length) {
      addRatePrice(rateAndPricesToAdd)
        .then(async () => {
          await this.loadData();
          if (showToast) {
            this.$toast.success('The changes were successfully saved');
          }
        })
        .catch((error: ApiError) => {
          switch (error.type) {
            case 'not_found':
              this.showError(error.message ?? 'Not Found');
              break;
            case 'internal_server_error':
            case 'action_not_found':
            case 'incorrect_payload':
            default:
              this.showError('Internal Server Error');
              break;
          }
        })
        .finally(() => {
          this.$store.commit('endGlobalSaveLoading');
        });
    }
  }

  public unitForTimeSeries(unit: number): string {
    switch (unit) {
      case 1:
        return 'USD/day';
      case 2:
        return 'USD mill.';
      case 3:
        return 'USD/LDT';
      default:
        return 'N/A';
    }
  }

  async showWarningPrompt(warnings: IFailedValidationResult[]): Promise<boolean> {
    return this.$bvModal.msgBoxConfirm([this.getBodyForWarning(warnings)], {
      id: 'warning-modal',
      title: 'Saving warning',
      size: 'lg',
      buttonSize: 'sm',
      okVariant: 'outline-secondary',
      footerClass: 'p-2',
      cancelTitle: 'Cancel',
      okTitle: 'Continue and save',
      hideHeaderClose: false,
      centered: true,
      noCloseOnBackdrop: true,
      noCloseOnEsc: true
    });
  }

  private getBodyForWarning(warnings: IFailedValidationResult[]): VNode {
    const message = warnings.flatMap(x => x.validationRuleMessage).join('<br>');

    const h = this.$createElement;
    const messageVNode = h('div', { class: ['modal-complete'] }, [
      h('div', {
        domProps: {
          innerHTML:
            '<span>Are you sure you want to save? Please be aware of this issue:</span><br><br>' +
            '<div class="warning-modal-body"><span><b>Warning: </b><br>' +
            `${message}` +
            '<br></span></div>'
        }
      })
    ]);

    return messageVNode;
  }

  public dateFormatByFrequency(frequency: string): string {
    switch (frequency) {
      case 'Daily':
      case 'Weekly':
        return 'ddd D.MM.YYYY';
      case 'Monthly':
        return 'MMM YYYY';
      default:
        return 'LL';
    }
  }

  private getErrorFromResponse(errors: ValidationErrors): string {
    var error = 'Please correct -';

    for (const key in errors) {
      error = error.concat(' ');
      error = error.concat(errors[key][0]);
    }

    return error;
  }

  private getErrors(): string {
    let errorsObj = new Map();
    var error = '';

    this.errors.forEach((value, key) => {
      errorsObj.set(key.split('.')[1], value);
    });

    errorsObj.forEach((value, key) => {
      if (error === '') {
        error = error.concat('Please correct -');
      }
      error = error.concat(' ');
      error = error.concat(value);
    });

    if (error === '') {
      error = 'Some data is invalid!';
    }
    return error;
  }

  private getErrorById(id: string): string | undefined {
    let errorsObj = new Map();

    this.errors.forEach((value, key) => {
      errorsObj.set(key.split('.')[0], value);
    });

    return errorsObj.get(id);
  }

  private validateRules(e: any, isExclamation: boolean): void {
    if (this.isNumber(e, isExclamation)) {
      this.checkError(e, isExclamation);
    }
  }

  updateFilteredData() {
    this.filteredData = [...this.timeSeries];
    if (this.isGlobalFilterEmpty()) {
      return;
    }

    if (this.contextFilters!.areaId) {
      this.filteredData = this.filteredData.filter(
        fd => fd.values[0].timeSerie.area.areaId === this.contextFilters!.areaId
      );
    }

    if (this.contextFilters!.businessId) {
      this.filteredData = this.filteredData.filter(
        fd => fd.values[0].timeSerie.business.businessId === this.contextFilters!.businessId
      );
    }

    if (this.contextFilters!.frequencyId)
      this.filteredData = this.filteredData.filter(
        fd => fd.values[0].timeSerie.frequencyId === this.contextFilters!.frequencyId
      );

    if (this.contextFilters!.isOverdueTimeSeriesOnly) {
      this.filteredData = this.filteredData.filter(this.filterTimeSerieGroup);
    }
    this.filteredData.forEach(d => d.visible = true);
  }

  filterTimeSerieGroup(timeSeriesGroup: IGroupedTimeSeries) {
    return (
      timeSeriesGroup.values.length > 0 &&
      // Only the first column left to current column without value is considered overdue
      timeSeriesGroup.values.some(function (timeSerie) {
        return !timeSerie.timeSerie.ratePrices[4].value;
      })
    );
  }

  isGlobalFilterEmpty(): boolean {
    return (
      this.contextFilters === null ||
      (this.contextFilters.areaId === null &&
        this.contextFilters.businessId === null &&
        this.contextFilters.frequencyId === null &&
        this.contextFilters.isOverdueTimeSeriesOnly === null)
    );
  }

  toTimeSeriesGroups(timeSeries: TimeSerieDto[]): IGroupedTimeSeries[] {
    const groupToValues = timeSeries.reduce((obj, item: TimeSerieDto) => {
      const group = `${item.area.name}-${item.business.name} ${item.frequency.toLowerCase()}`;

      item.ratePrices.forEach(rate => {
        const value = this.timeSerieInputFormatter(rate.value, item.area.unit);
        rate.value = toNumber(value) === -1 ? null : value;
      });

      obj[group] = obj[group] || [];
      obj[group].push({
        value: null,
        timeSerie: item,
        unit: this.unitForTimeSeries(item.area.unit)
      });

      return obj;
    }, {});
    // intentionally set first 3000 groups to be visible
    let count = 0;
    return Object.keys(groupToValues).map(key => {
      return {
        key: key,
        values: groupToValues[key],
        visible: count++ < 3000
      };
    });
  }

  private isOldValueDeleted(value: any, oldValue: any): any {
    if (isEmpty(value) && !isEmpty(oldValue) && !this.currentUser.isAdmin) {
      this.$bvModal.msgBoxOk(
        'You are not permitted to delete values in the data field. Please input data or contact the Rates and Prices administrator. ',
        {
          title: 'Saving warning',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'outline-secondary',
          footerClass: 'p-2',
          okTitle: 'No, go back',
          hideHeaderClose: false,
          centered: true
        }
      );
      return true;
    }

    return false;
  }

  dateToLocalString(date?: string | Date, frequency?: string): string {
    let formatToApply = 'LL';
    if (frequency) {
      formatToApply = this.dateFormatByFrequency(frequency);
    }

    return dayjs(date).format(formatToApply);
  }

  validateValue(value: any): any {
    if (!this.currentUser.isAdmin && isEmpty(value)) {
      this.$bvModal.msgBoxOk(
        'You are not permitted to delete values in the data field.Please input data or contact the Rates and Prices administrator. ',
        {
          title: 'Saving warning',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'outline-secondary',
          footerClass: 'p-2',
          okTitle: 'No, go back',
          hideHeaderClose: false,
          centered: true
        }
      );
      return false;
    }
    return true;
  }

  private isNumber(e: any, isExclamation: boolean): boolean {
    e = e ? e : window.event;
    var charCode = e.which ? e.which : e.keyCode;

    if (e.target.value === '' && (charCode === 8 || charCode === 1)) {
      this.valid(e, isExclamation);
      this.errors.delete(`${e.target.id}.string`);
      this.errors.delete(`${e.target.id}.comma`);

      e.target.placeholder = 'add value';

      return false;
    }

    if (charCode === 188) {
      this.notValid(e, isExclamation);
      this.errors.set(`${e.target.id}.comma`, "Comma ',' is not allowed in the given value.");

      e.target.placeholder = 'Incorrect value';

      return false;
    }

    var numberStr = this.getValue(e.target.value, charCode);
    var result = !isNaN(numberStr) && isFinite(numberStr);

    if (!result) {
      this.notValid(e, isExclamation);
      this.errors.set(`${e.target.id}.string`, 'String is not allowed in the given value.');
      e.target.placeholder = 'Incorrect value';
    } else {
      this.valid(e, isExclamation);
      this.errors.delete(`${e.target.id}.string`);
      this.errors.delete(`${e.target.id}.comma`);
      e.target.placeholder = 'add value';
    }

    return result;
  }

  private valid(e: any, isExclamation: boolean): void {
    if (isExclamation) {
      e.target.classList.remove('tb-input-exclamation');
    }

    e.target.classList.remove('red');
    document.getElementById(`v_${e.target.id}`)?.style.setProperty('display', 'block');
    document.getElementById(`n_${e.target.id}`)?.style.setProperty('display', 'none');
  }

  private notValid(e: any, isExclamation: boolean): void {
    if (isExclamation) {
      e.target.classList.add('tb-input-exclamation');
    }

    e.target.classList.add('red');
    document.getElementById(`n_${e.target.id}`)?.style.setProperty('display', 'block');
    document.getElementById(`v_${e.target.id}`)?.style.setProperty('display', 'none');
  }

  private getValue(value, charCode: any): any {
    var fromCharCode = '';
    if (charCode !== 8 && charCode !== 1) {
      fromCharCode = String.fromCharCode(charCode);
    }
    return value + fromCharCode;
  }

  private checkError(e: any, isExclamation: boolean): void {
    if (this.getErrorById(e.target.id) !== undefined) {
      this.notValid(e, isExclamation);
    } else {
      this.valid(e, isExclamation);
    }
  }

  createRatePriceModel(): SegmentPricePair[] {
    var populatedPrices: SegmentPricePair[] = [];

    this.filteredData.forEach(group => {
      let groupArray = Array<any>();

      group.values.forEach(item => {
        if (item.value !== null) {
          groupArray.push({
            segmentId: item.timeSerie.timeSeriesId,
            value: item.value,
            date: null
          });
        }
      });

      Array.prototype.push.apply(populatedPrices, groupArray);
    });

    this.changes.forEach(item => {
      if (item !== null && item.value !== '') {
        populatedPrices.push(item);
      } else {
        this.deletedItems.push({
          segmentId: item.segmentId,
          value: item.value,
          date: item.date
        });
      }
    });

    return populatedPrices;
  }

  checkUnfilledChangesInGroup(key: string): boolean {
    let result = false;

    let group = this.filteredData.filter(obj => {
      return obj.key === key;
    });

    for (var i = 0; i < group[0].values.length; i++) {
      if (this.checkUnfilledChanges(group[0].values[i].timeSerie.ratePrices)) {
        result = true;
        break;
      }
    }

    return result;
  }

  checkUnfilledChanges(obj: Array<any>): boolean {
    return (
      obj.filter(function (val) {
        return val.value != null;
      }).length != obj.length
    );
  }

  async copyValues(key: string, event: any): Promise<void> {
    if (event.target.classList.contains('undo')) {
      this.undoValues(key);
      event.target.classList.remove('undo');
      return;
    }

    if (this.hasInputValues(key)) {
      if (
        !(await this.showPrompt(
          'Pre-existing values detected.\nDo you want to overwrite the pre-existing values?',
          'Duplicate Weekly Time Series Data'
        ))
      )
        return;
    }

    let row = 0;
    while (true) {
      let source = document.getElementById(`price-input-${key}-${row}-4`) as HTMLInputElement;
      let destination = document.getElementById(`price-input-${key}-${row}-5`) as HTMLInputElement;
      if (source !== null && destination != null) {
        destination.focus(); // to trigger formatter
        destination.value = source.value.replace(/,/g, '');
        destination.blur();
        row += 1;
      } else {
        break;
      }
    }
    event.target.classList.add('undo');
    let timeSeriesId = this.filteredData[key].values[0].timeSerie.timeSeriesId;
    if (this.copyPasted.indexOf(timeSeriesId) == -1) this.copyPasted.push(timeSeriesId);
  }

  private async undoValues(key: string): Promise<void> {
    let timeSeriesId = this.filteredData[key].values[0].timeSerie.timeSeriesId;
    var targetTimeSerie = this.initialTimeSeries.find(
      x => x.values[0].timeSerie.timeSeriesId == timeSeriesId
    );
    if (targetTimeSerie) {
      let row = 0;

      while (true) {
        let destination = document.getElementById(`price-input-${key}-${row}-5`) as HTMLInputElement;
        if (destination !== null) {
          destination.focus();

          destination.value = isEmpty(targetTimeSerie.values[row].timeSerie.ratePrices[5].value)
            ? ''
            : targetTimeSerie.values[row].timeSerie.ratePrices[5].value!.replace(/,/g, '');

          destination.blur();
          row += 1;
        } else {
          break;
        }
      }
      this.copyPasted = this.copyPasted.filter(function (value, index, arr) {
        return value != timeSeriesId;
      });
    }
  }

  private hasInputValues(key: string): boolean {
    let row = 0;
    let result = false;
    while (true) {
      let input = document.getElementById(`price-input-${key}-${row}-5`) as HTMLInputElement;
      if (input !== null) {
        if (input.value != '') {
          result = true;
          break;
        }
        row += 1;
      } else {
        break;
      }
    }
    return result;
  }

  private async showPrompt(message: string, title?: string, cancelTitle?: string): Promise<boolean> {
    return await this.$bvModal.msgBoxConfirm(message, {
      title: title ? title : 'Warning',
      headerBgVariant: 'card-header-label',
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'secondary',
      footerClass: 'p-2',
      okTitle: 'Yes',
      cancelTitle: cancelTitle ? cancelTitle : 'Cancel',
      hideHeaderClose: true,
      centered: true
    });
  }

  showError(message: string): void {
    this.$bvModal.msgBoxOk(message, {
      title: 'Saving error',
      size: 'sm',
      buttonSize: 'sm',
      headerBgVariant: 'danger',
      okVariant: 'danger',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    });
  }

  private isDataValid(): boolean {
    const isValidNumberRegExp = new RegExp('^[-]?\\d+(\\.\\d+)?$', '');

    var isFilteredDataValid = !this.filteredData.some(x =>
      x.values.some(y => y.value !== null && parseFloat(y.value.replace(/,/g, '')))
    );

    var areChangesValid = !this.changes.some(
      x =>
        (x.value !== null && !isValidNumberRegExp.test(x.value.replace(/,/g, ''))) ||
        parseFloat(x.value.replace(/,/g, '')) < 0
    );

    return isFilteredDataValid && areChangesValid;
  }

  private ratePriceChanged(date: any, value: string | null, segmentId: number, key: string): void {

    var oldValue = this.initialTimeSeries
      .flatMap(x => x.values)
      .find(y => y.timeSerie.timeSeriesId == segmentId)!
      .timeSerie.ratePrices.find(y => y.date == date)!.value;

    if (this.isOldValueDeleted(value, oldValue)) {
      const targetTimeSerie = this.timeSeries.find(x => x.values[0].timeSerie.timeSeriesId == segmentId);

      if (targetTimeSerie) {
        const targetRatePrice = targetTimeSerie.values[0].timeSerie.ratePrices.find(y => y.date == date);

        if (targetRatePrice) {
          targetRatePrice.value = oldValue;
        }
      }
      return;
    }

    const update = {
      what: updateRatePrice,
      date: date,
      value: value,
      segmentId: segmentId,
      key: key
    };
    
    const previousEntry = this.changes.findIndex(
      e => e.what == updateRatePrice && e.date == update.date && e.segmentId == update.segmentId
    );

    if (oldValue == value || (oldValue == null && value == '')) {
      if (previousEntry >= 0) this.changes.splice(previousEntry, 1);
      return;
    }
    
    // value is updated
    if (previousEntry >= 0) {
      this.changes.splice(previousEntry, 1, update);
    } else {
      this.changes.push(update);
    }
    
    this.onSaveClicked(false);
  }

  timeSerieInputFormatter(value: number | string | null, unit: number) {
    return value ? formatForUnit(value, unit) : null;
  }

  inputFormatter(value: string) {
    return value.length ? formatToFixedPrecision(value) : null;
  }

  beforeRouteLeave(to, from, next) {
    let obj = this.createRatePriceModel();

    if (obj.length == 0 || window.confirm('You have unsaved changes. Do you wish to leave this page?')) {
      next();
    } else {
      next(false);
    }
  }

  onBeforeUnload(event: any) {
    let obj = this.createRatePriceModel();

    if (obj.length == 0) {
      return;
    }

    event.preventDefault();
    event.returnValue = '';
  }

  beforeDestroy(): void {
    window.removeEventListener('beforeunload', this.onBeforeUnload);
    eventBus.$off('onSaveClick', this.onSaveClicked);
    eventBus.$off('appliedFilters', this.onFiltersApplied);
  }
}

export interface SizeFilters {
  [key: string]: Size[];
}

export interface AreaTypeFilters {
  [key: string]: AreaType[];
}
