



















import { Frequency } from '@model/Common';
import { ANY_PERIOD } from '@model/Filters';
import { Component, Vue, Prop, Model } from 'vue-property-decorator';

interface SelectOption {
  id: number;
  name: string;
}

@Component({
  components: {}
})
export default class MBFrequencySelect extends Vue {
  @Model('change', { default: null }) value!: SelectOption | SelectOption[] | number | number[] | null;
  @Prop({ default: false, type: Boolean }) allowAny!: boolean;
  @Prop({ default: false, type: Boolean }) multiple!: boolean;

  multiClass = {
    'multiselect-thead vertical-tags': this.multiple
  };

  multiProps = {
    'close-on-select': false,
    'clear-on-select': true,
    taggable: false,
    'show-labels': false,
    'option-height': 35
  };

  singleProps = {
    searchable: false,
    'allow-empty': false,
    'internal-search': false,
    'show-labels': false
  };

  get options(): SelectOption[] {
    const result: SelectOption[] = [];

    if (this.allowAny) {
      result.push(ANY_PERIOD);
    }

    this.$store.state.periods.forEach((a: Frequency) => result.push(a));

    return result;
  }

  isSelectOption(object: unknown): object is SelectOption {
    return (
      'name' in (object as any) &&
      'id' in (object as any) &&
      typeof (object as any).name === 'string' &&
      typeof (object as any).id === 'number'
    );
  }

  get controlValue(): SelectOption | SelectOption[] | null {
    if (this.multiple) {
      if (!Array.isArray(this.value) || this.value.length === 0) {
        return this.allowAny ? [ANY_PERIOD] : [];
      }

      if (typeof this.value[0] === 'number') {
        return this.options.filter(x => (this.value as number[]).some(v => v == x.id));
      }

      if (this.isSelectOption(this.value[0])) {
        return this.value as SelectOption[];
      }
    } else {
      if (!this.value) {
        return this.allowAny ? ANY_PERIOD : null;
      }

      if (typeof this.value === 'number') {
        return this.options.find(x => x.id === this.value) ?? null;
      }

      if (this.isSelectOption(this.value)) {
        return this.value;
      }
    }

    return null;
  }

  updateSelected(option: SelectOption | SelectOption[]) {
    if (Array.isArray(option)) {
      const allPeriods = option.find(x => x.id === ANY_PERIOD.id);
      this.$emit('change', allPeriods ? [] : option.map(x => x.id));
    } else {
      this.$emit('change', option.id === ANY_PERIOD.id ? null : option.id);
    }
  }
}
