import { Area, Business, DataOwner, Size, Frequency, IdNamePair } from '@model/Common';
import { ImportSource } from '@model/ImportData';
import Vue from 'vue';
import Vuex from 'vuex';

import filtersContext from './modules/filtersContext';

export interface AppState {
  globalSaveLoading: boolean;
  areas: Area[];
  businesses: Business[];
  periods: Frequency[];
  users: DataOwner[];
  dataOwners: DataOwner[];
  sizes: Size[];
  importSources: ImportSource[];

  filters: {
    isLoading: boolean;
    areas: IdNamePair<number>[];
    businesses: IdNamePair<number>[];
    periods: IdNamePair<number>[];
    dataOwners: IdNamePair<number>[];
    importSources: ImportSource[];
  };
}

Vue.use(Vuex);

export const initState: AppState = {
  globalSaveLoading: false,

  areas: [],
  businesses: [],
  periods: [],
  users: [],
  dataOwners: [],
  importSources: [],
  sizes: [],

  filters: {
    isLoading: false,
    areas: [],
    businesses: [],
    periods: [],
    dataOwners: [],
    importSources: []
  }
};

export default new Vuex.Store<AppState>({
  state: initState,
  getters: {
    isGlobalSaveLoading(state) {
      return state.globalSaveLoading;
    }
  },
  modules: {
    filtersContext
  },
  mutations: {
    //GLOBAL SAVE LOADING
    startGlobalSaveLoading(state) {
      state.globalSaveLoading = true;
    },
    endGlobalSaveLoading(state) {
      state.globalSaveLoading = false;
    },

    areasReceived(state, data: Area[]) {
      state.areas = data;
    },

    businessesReceived(state, data: Business[]) {
      state.businesses = data;
    },

    periodsReceived(state, data: Frequency[]) {
      state.periods = data;
    },

    sizesReceived(state, data: Size[]) {
      state.sizes = data;
    },

    usersReceived(state, data: DataOwner[]) {
      state.users = data;
    },

    dataOwnersReceived(state, data: DataOwner[]) {
      state.dataOwners = data;
    },

    importSourcesReceived(state, data: ImportSource[]) {
      state.importSources = data;
    },

    // FILTERS

    filtersLoadingStarted(state) {
      state.filters.isLoading = true;
    },

    filtersLoadingCompleted(state) {
      state.filters.isLoading = false;
    },

    areasFilterDataReceived(state, data) {
      state.filters.areas = data;
    },

    buisinessesFilterDataReceived(state, data) {
      state.filters.businesses = data;
    },

    dataOwnersFilterDataReceived(state, data) {
      state.filters.dataOwners = data;
    },

    periodsFilterDataReceived(state, data) {
      state.filters.periods = data;
    },

    importSourcesFilterDataReceived(state, data: ImportSource[]) {
      state.filters.importSources = data;
    }
  }
});
